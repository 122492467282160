/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import LogoIconGray from "../assets/images/logo-icons-gray.svg";
import SlickSlider from "../components/basketballSlider";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";
import { tabTitle } from "../components/tabsTitle";
import CreateAccountPopup from "../components/createAccountPopup";
import { PopupModal } from "react-calendly";
import authContext from "../store/authContext";

export default function BasketballConcierge() {
  tabTitle("Basketball Concierge");
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [createPopupOpen, setCreatePopupOpen] = useState(false);
  const { authenticated } = useContext(authContext);

  const openCreatePopup = () => setCreatePopupOpen(true);
  const closeCreatePopup = () => setCreatePopupOpen(false);
  const openCalendlyModal = () => setCalendlyModalOpen(true);

  return (
    <div id="wrapper">
      <div className="bb-cngr-cnt">
        <Container>
          <PopupModal
            url="https://calendly.com/game-up-team/game-up-call"
            onModalClose={() => setCalendlyModalOpen(false)}
            open={calendlyModalOpen}
            rootElement={document.getElementById("root")}
            
          />
          <Row>
            <Col md={12}>
              <h2>Get in touch with a basketball concierge</h2>

              <a
                className="jn-btn"
                onClick={authenticated ? openCalendlyModal : openCreatePopup}
                style={{ cursor: "pointer" }}
              >
                SPEAK TO CONCIERGE
              </a>
            </Col>
            <SlickSlider />
          </Row>

          <Row className="exprnc-main">
            <Col md={12} className="exprnc-cnt">
              <h6>
                Discover the Ultimate Basketball Experience with GameUp's
                Basketball Concierge
              </h6>
              <p>
                Are you looking to give your child the best possible start in
                their basketball journey? Look no further! GameUp's Basketball
                Concierge is here to provide you with a personalized,
                stress-free experience in finding the perfect basketball team,
                program, and trainer for your child.
              </p>
              <p>
                Our experienced basketball concierges take the time to
                understand your child's age, skillset, goals, and location,
                ensuring that we recommend the most suitable options that will
                set them up for success.
              </p>
              <p>
                With so many teams, programs, and trainers out there, it can be
                overwhelming to find the right fit. Let our concierges do the
                hard work for you, so you can spend more time enjoying your
                child's progress and development on the court.
              </p>
            </Col>
            <Col className="exprnc-bg">
              <img src={LogoIconGray} alt="image" />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="hw-it-wrks">
            <Col md={12} className="hw-it-wrks-hd">
              <h5>How it works</h5>
            </Col>
          </Row>
          <Row className="steps-list">
            <Col lg={4} className="step-item">
              <div className="step-num">
                <h6>1</h6>
              </div>
              <span>step 1</span>
              <p>
                Provide us with essential information about your child,
                including their age, skillset, goals, and location. This will
                help us understand their unique needs and preferences, enabling
                us to offer personalized recommendations for the best possible
                basketball experience.
              </p>
            </Col>
            <Col lg={4} className="step-item">
              <div className="step-num">
                <h6>2</h6>
              </div>
              <span>step 2</span>
              <p>
                Schedule a call with one of our knowledgeable basketball
                concierges, who will take the time to discuss your child's
                needs, answer any questions you may have, and provide guidance
                on suitable options. With our expertise and connections, we can
                help you make well-informed decisions for your child's
                basketball journey.
              </p>
            </Col>
            <Col lg={4} className="step-item">
              <div className="step-num">
                <h6>3</h6>
              </div>
              <span>step 3</span>
              <p>
                Based on the consultation, our concierge will present you with a
                curated list of teams, programs, and trainers that match your
                child's requirements. Review the options, ask any follow-up
                questions, and select the best fit for your child. We'll then
                connect you with the chosen team, program, or trainer, setting
                your child up for a successful and enjoyable basketball
                experience.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="rdy-start">
            <Col md={5} lg={4} xs={12}>
              <h4>ready to get started?</h4>
            </Col>
            <Col className="" md={4} xs={12}>
              <a
                className="jn-btn"
                onClick={authenticated ? openCalendlyModal : openCreatePopup}
                style={{ cursor: "pointer" }}
              >
                SPEAK TO CONCIERGE
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      {createPopupOpen && <CreateAccountPopup closePopup={closeCreatePopup} />}

      <ChatbotFloatingButton />
    </div>
  );
}
