import { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { tabTitle } from "../components/tabsTitle";
import fbImg from "../assets/images/btn_fb_signin.png";
import googleImg from "../assets/images/btn_google_signin.png";
import authContext from "../store/authContext";


export default function LoginPage() {
  tabTitle("Login");
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(authContext);
  const [payload, setpayload] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setpayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onClick = async () => {
    try {
      setLoading(true);
      const login = await Auth.signIn({
        username: payload.username,
        password: payload.password,
      });
      setLoading(false);
      if (login) {
        const userData = await Auth.currentAuthenticatedUser();
        setAuthenticated(userData.attributes);
        navigate("/profile");
      }
    } catch (e) {
      setLoading(false);
      if (String(e)?.includes("userName")) {
        alert("Please input email address instead of the random stuff");
      } else {
        alert(e.message);
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <ClipLoader
            color={"#ffffff"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className="login-page pb-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="forms">
                <h1 className="py-5 mt-0 mt-md-5 mb-3">Login</h1>
                <Form className="login-form">
                  <Row className="form-inputs mx-auto py-4">
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="pt-2">Email</Form.Label>
                        <Form.Control
                          name="username"
                          type="email"
                          placeholder="Email"
                          onChange={onChange}
                          data-test="email-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Password"
                          data-test="password-input"
                          onChange={onChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="text-center py-1"
                        controlId="formBasicCheckbox"
                      >
                        <Link to="/forgot">Forgot password?</Link>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button
                        variant="primary"
                        className="form-btn mt-2"
                        data-test="submit-button"
                        onClick={onClick}
                      >
                        Let’s Go
                      </Button>
                    </Col>
                    {/*<Col md={12}>
                      <div className="text-center mt-5">
                        <img
                          style={{ borderRadius: 7, cursor: "pointer" }}
                          height={120}
                          width={350}
                          src={fbImg}
                          alt={"loginwithfb"}
                          onClick={() =>
                            Auth.federatedSignIn({
                              provider:
                                CognitoHostedUIIdentityProvider.Facebook,
                            })
                          }
                        />
                      </div>
                      <div className="text-center">
                        <img
                          style={{ borderRadius: 7, cursor: "pointer" }}
                          height={62}
                          width={310}
                          src={googleImg}
                          alt={"loginwithfb"}
                          onClick={() =>
                            Auth.federatedSignIn({
                              provider: CognitoHostedUIIdentityProvider.Google,
                            })
                          }
                        />
                      </div>
                      <Button variant="primary" className="form-btn mt-5 mb-4">
                            Login with Facebook
                          </Button>
                      <Button variant="primary" className="form-btn">
                        Login with Google
                      </Button>
                    </Col>*/}
                  </Row>
                  <hr></hr>
                  <div className="form-inputs mx-auto pt-4 pb-5 text-center register-section px-2">
                    <h2>New to Gameup?</h2>
                    <Link
                      to="/joingame"
                      className="form-btn btn btn-primary text-decoration-none register-btn my-3 mx-md-auto"
                    >
                      Register for a new account
                    </Link>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
