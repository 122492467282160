import { useEffect } from "react";

export default function useKeyboardPress(callback, keys) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      const wasAnyKeyPressed = keys.some((key) => e.key === key);

      if (wasAnyKeyPressed) {
        e.preventDefault();
        callback();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
}
