import React, { useState } from "react";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import CloseIcon from "../assets/images/icons/close-icon.svg";

export default function DenyReasonPopup(props) {
  const [denialReason, setDenialReason] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (!denialReason.trim()) {
      return;
    }

    props.handleApproval(props.teamToDenyId, false, denialReason);
    props.closePopup();
  };

  return (
    <div className="deny-popup-main bg-info" id="popup-deny">
      <Container>
        <Row>
          <Col className="deny-cncl" onClick={props.closePopup}>
            <img src={CloseIcon} alt="close icon" />
          </Col>
          <h4>Deny Request?</h4>
        </Row>

        <Form onSubmit={onSubmit} className="dny-form">
          <Row>
            <Col md={12} className="dny-list">
              <Form.Group>
                <Form.Label>Reason for denial:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  onChange={(e) => setDenialReason(e.target.value)}
                  data-test="deny-reason-input"
                />
              </Form.Group>
            </Col>
            <Col md={12} className="dny-btn-grup">
              <Button
                variant="primary"
                type="submit"
                className="dny-btn"
                data-test="deny-button"
              >
                Deny
              </Button>
              <Button
                variant="primary"
                className="cncl-btn"
                onClick={props.closePopup}
              >
                cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}
