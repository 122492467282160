import { useEffect, useState } from "react";

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export default function useGeolocation() {
  const [permissionState, setPermissionState] = useState("");
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((position) => {
              const newLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              setPermissionState(result.state);
              setLocation(newLocation);
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const newLocation = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                setPermissionState(result.state);
                setLocation(newLocation);
              },
              (e) => {
                console.log(e);
              },
              geolocationOptions
            );
          }
          result.onchange = () => {
            setPermissionState(result.state);
          };
        });
    } else {
      setPermissionState("denied");
    }
  }, []);

  return {
    permissionState,
    location,
  };
}
