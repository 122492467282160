import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserIcon from "../assets/images/icons/user-black-icon.svg";
import DenyReasonPopup from "../components/denyReasonPopup";
import { tabTitle } from "../components/tabsTitle";
import Footer from "../components/footer";
import GameUpStreams from "../components/GameupStreams";
import { Link } from "react-router-dom";

export default function AccountSettings() {
  tabTitle("Account Settings");
  //const [denyOpen, setDenyOpen] = useState(false);
  const [editingMode, setEditingMode] = useState(false);

  //const openPopup = () => setDenyOpen(true);
  //const closePopup = () => setDenyOpen(false);

  return (
    <div id="wrapper">
      <div className="admin-stngs-main">
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <div className="account-settings-wrapper">
              <div className="d-flex justify-content-between w-100 mb-3 mt-5">
                <Link to="/" className="text-white text-decoration-none">
                  <span>&lt;</span>
                  <span className="fw-bold"> GO BACK</span>
                </Link>
                <Link
                  to="/profile"
                  className="text-white text-decoration-none fw-bold"
                >
                  VIEW PROFILE
                </Link>
              </div>
              <Col className="account-settings-cnt">
                <div className="ad-hd">
                  <Row className="ad-list">
                    <Col className="ad-hd-lft">
                      <img src={UserIcon} alt="image" />
                      <p>Account Settings</p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      {editingMode ? (
                        <div className="d-flex gap-4">
                          <button
                            onClick={() => setEditingMode(false)}
                            data-test="save-changes-button"
                          >
                            Save
                          </button>
                          <button
                            className="cancel"
                            onClick={() => setEditingMode(false)}
                            data-test="cancel-changes-button"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => setEditingMode(true)}
                          data-test="edit-button"
                        >
                          Edit
                        </button>
                      )}
                    </Col>
                  </Row>
                </div>
                <Row className="ad-cnt">
                  <Col md={12} className="ad-cnt-lst d-flex flex-column gap-3">
                    <div>
                      <p>Name</p>
                      {editingMode ? (
                        <input data-test="name-input" />
                      ) : (
                        <span>Admin Name</span>
                      )}
                    </div>
                    <div>
                      <p>address</p>
                      {editingMode ? (
                        <textarea data-test="address-input" />
                      ) : (
                        <>
                          <span>Address Line 1</span>
                          <span>Address Line 2</span>
                          <span>City, ST 12345</span>
                        </>
                      )}
                    </div>
                    <div>
                      <p>email address</p>
                      {editingMode ? (
                        <input data-test="email-input" />
                      ) : (
                        <span>admin@gameup.fyi</span>
                      )}
                    </div>
                    <div>
                      <p className="d-flex align-items-center gap-3">
                        Password {!editingMode && <span>Change Password</span>}{" "}
                      </p>

                      {editingMode ? <input /> : <span>***************</span>}
                    </div>

                    {editingMode && (
                      <div>
                        <p>Confirm Password</p>
                        <input />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
          <GameUpStreams color="white" />
        </Container>
        <Footer color="white" />
      </div>

      {/* {denyOpen && <DenyReasonPopup closePopup={closePopup} />} */}
    </div>
  );
}
