import React, { useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { ClipLoader } from "react-spinners";

import CloseIconWhite from "../assets/images/icons/close-icon-white.svg";

export default function CreateAccountPopup(props) {
  const navigate = useNavigate();

  const [payload, setpayload] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setpayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onClick = async () => {
    if (payload.password !== payload.confirmPassword) {
      alert("Passwords must match");

      return;
    }
    
    try {
      setLoading(true);
      const signUp = await Auth.signUp({
        username: payload.username,
        password: payload.password,
        attributes: {
          email: payload.username,
          name: payload.name,
        },
      });
      console.log("signUp: ", signUp);
      if (signUp) {
        setLoading(false);
        console.log("payload.username: ", payload.username);
        navigate("/verify", {
          state: { email: payload.username, password: payload.password },
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e)
      alert(e.message);
    }
  };

  return (
    <div className="create-acnt-main" id="popup">
      {loading && (
          <div className="loader">
            <ClipLoader
              color={"#ffffff"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      <Container>
        <Row>
          <Col className="crct-acnt-hd">
            <h5>you’ll need to create an account to do that</h5>
            <div className="acnt-cncl" onClick={props.closePopup} aria-label="close popup" data-test="close-popup">
              <img src={CloseIconWhite} alt="image" />
            </div>
          </Col>
        </Row>

        <Form className="crct-acnt-form">
          <Row className="crct-form-list">
            <Col md={6} className="crct-form-item">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder=""
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="crct-form-item">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  placeholder=""
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="crct-form-item">
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder=""
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="crct-form-item">
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder=""
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="crct-form-btn">
              <Button variant="primary"
                        className="form-btn mt-5 mb-4"
                        onClick={onClick}>
                Let’s Go!
              </Button>
            </Col>
            <Col md={12}>
              <span>
                Already have an account? <Link to="/login">Login</Link>
              </span>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}
