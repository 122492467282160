import React, { useState } from "react";
import { Container, Row, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Footer from "../components/footer";
import CreateAccountPopup from "../components/createAccountPopup";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";
import GameUpStreams from "../components/GameupStreams";

import { useNavigate } from "react-router-dom";

export default function SearchLanding() {
  const [createPopupOpen, setCreatePopupOpen] = useState(false);
  const footerColor = "rgb(255 255 255 / 80%)";

  const openCreatePopup = () => setCreatePopupOpen(true);

  const closeCreatePopup = () => setCreatePopupOpen(false);

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function captureEmail() {
    if (email === "" || `${email}`.indexOf("@") === -1) {
      alert("Please enter your email address");
      return;
    } else {
      console.log(email);
      // Send email to API Gateway Lambda as POST. https://gpk3uc64oa.execute-api.us-east-1.amazonaws.com/prod/
      fetch("https://gpk3uc64oa.execute-api.us-east-1.amazonaws.com/prod/", {
        method: "POST",
        body: email,
        mode: "no-cors",
      });

      navigate("/query");
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      captureEmail();
    }
  };

  return (
    <>
      <div className="search_landing-page">
        <Container>
          <Row>
            <Col md={12} className="search-cnt">
              <h2>make gameup your Youth Basketball destination</h2>
              <p>
                “As a veteran basketball mom, I know first-hand how difficult it
                is to navigate the youth basketball landscape. I created GameUp
                to help you take the guess work out of finding the best program
                for your child. Make GameUp your youth basketball destination!”
              </p>
              <span>
                Crystal McCrary <b> & </b> <br /> <small>cole anthony</small>
              </span>

              <Form className="jn-frm">
                <Row>
                  <Col>
                    <p>
                      Join the <b>30,000+</b> other ballers who are using GameUp
                      to find their perfect basketball match!
                    </p>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email address*"
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleKeyPress}
                      data-test="email-input"
                    />
                    <a
                      className="jn-btn"
                      onClick={() => captureEmail()}
                      data-test="submit-button"
                      style={{ cursor: "pointer" }}
                    >
                      Let’s go
                    </a>
                    <span>
                      Already have an account? <Link to="/login">Login</Link> or{" "}
                      <a onClick={openCreatePopup}>CREATE AN ACCOUNT</a>
                    </span>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <GameUpStreams />

        {createPopupOpen && (
          <CreateAccountPopup closePopup={closeCreatePopup} />
        )}
        <ChatbotFloatingButton />
        <Footer color={footerColor} />
      </div>
    </>
  );
}
