import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Geocode from "react-geocode";
import ResultsSlider from "../components/resultsSlider";
import { tabTitle } from "../components/tabsTitle";
import { Link, useLocation } from "react-router-dom";
import arrowLeftIcon from "../assets/images/icons/back-arrow.svg";
import saveIcon from "../assets/images/icons/save-icon.svg";
import GameUpStreams from "../components/GameupStreams";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";


Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage("en");

async function getCoordinatesFromZipCode(zipCode) {
  try {
    const { results } = await Geocode.fromAddress(zipCode + " USA");

    if (results.length > 0) {
      const { lat, lng } = results[0].geometry.location;
      return { lat, lng };
    }

    return null;
  } catch (e) {
    return null;
  }
}

async function addCoordToTrainers(trainers) {
  const trainersWithCoords = await Promise.all(
    trainers.map(async (trainer) => {
      if (trainer.zipcode) {
        const coordinates = await getCoordinatesFromZipCode(trainer.zipcode);
        return { ...trainer, coordinates };
      }
      return trainer;
    })
  );

  return trainersWithCoords;
}

export default function ResultsTrainers() {
  tabTitle("Results");
  const { state } = useLocation();
  const { trainers: initialTrainers, filters: initialFilters } = state;
  const [trainers, setTrainers] = useState(initialTrainers);
  const [selectedView, setSelectedView] = useState("grid");

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    addCoordToTrainers(trainers).then((trainers) => setTrainers(trainers));
  }, []);

  return (
    <>
      <ChatbotFloatingButton />
      <div className="form-layouts py-5 results-page">
        <Container>
          <Row>
            <Col md={12} className="position-relative">
              <div className="forms py-2">
                <h1 className="py-5">
                  Here are the trainers we found
                  <br />
                  <span className="text-black"></span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="filters-section">
        <Container>
          <Col md={12}>
            <Row>
              <div className="d-flex justify-content-lg-start justify-content-between gap-5 filter-bar-controls">
                <Link
                  to="/query"
                  className="d-flex gap-3 align-items-center text-uppercase position-relative"
                >
                  <img src={arrowLeftIcon} />
                  edit search
                </Link>
                <Button className="d-flex gap-3 align-items-center text-uppercase position-relative">
                  <img src={saveIcon} />

                  {isMobile ? "Save" : "Save this search"}
                </Button>
              </div>
            </Row>
          </Col>
        </Container>
        {/* <div className="separator w-100" /> */}
        {/* <Container>
          <Col>
            <Row>
              <div className="mt-2 d-flex justify-content-between align-items-lg-center flex-lg-row flex-column gap-lg-0 gap-4">
                <div className="d-flex align-items-lg-center">
                  <span className="text-black fw-semibold me-3">FILTERS:</span>
                  <div className="d-flex align-items-center gap-2 badges-list">
                    {Object.keys(filters)
                      .filter((item) => !!filters[item])
                      .map((key, idx) => (
                        <span
                          key={idx}
                          className="filter-select-val d-flex align-items-center text-capitalize"
                        >
                          {filters[key]} {key === "age" && " years old"}
                          <Badge
                            bg=""
                            className="pe-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => removeFilterValue(key)}
                          >
                            <img
                              className=""
                              src={FilterCross}
                              alt="FilterCross"
                            />
                          </Badge>
                        </span>
                      ))}
                  </div>
                </div>
                <div className="d-flex align-items-lg-center filter-selectors flex-lg-row flex-column">
                  <div className="d-flex align-items-center">
                    <span className="text-black fw-semibold me-3">VIEW:</span>
                    <div className="d-flex align-items-center gap-1">
                      {viewOptions.map((item) => (
                        <button
                          key={item.value}
                          onClick={() => setSelectedView(item.value)}
                          className={
                            "border-0 view-button" +
                            (selectedView === item.value ? " active" : "")
                          }
                        >
                          <img src={item.icon} alt={item.alt} />
                        </button>
                      ))}
                    </div>
                  </div>
                  {selectedView !== "map" && (
                    <div className="d-flex align-items-center">
                      <span className="text-black fw-semibold me-3">SORT:</span>
                      <Form.Select
                        id="dropdown-basic-button"
                        className="dropdown-basic-button"
                        title="Select..."
                        value={selectedSortingMethod}
                        onChange={(e) =>
                          setSelectedSortingMethod(e.target.value)
                        }
                      >
                        {sortingOptions
                          .filter((item) =>
                            permissionState === "granted"
                              ? item
                              : item.value !== "distance"
                          )
                          .map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Container> */}
      </div>

      {selectedView === "map" ? (
        // <MapWithMarkers markersData={markersData} groupedTeams={groupedTeams} />
        <div></div>
      ) : (
        <div className="pt-5 my-5">
          <Container>
            <Row>
              <Col
                md={12}
                className="results-slider-section"
                style={{ marginBottom: 0 }}
              >
                {trainers.length > 0 ? (
                  <>
                    <ResultsSlider cardsData={trainers} cardType="trainer" />
                    <br />
                  </>
                ) : (
                  <>
                    <br />
                    <h4 className="text-center">No result found...</h4>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <GameUpStreams color="orange" />
    </>
  );
}
