import { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { tabTitle } from "../components/tabsTitle";
import authContext from "../store/authContext";

export default function VerifyPage() {
  const navigate = useNavigate();
  const location = useLocation();
  tabTitle("Forgot");
  const [payload, setpayload] = useState({});
  const [loading, setLoading] = useState(false);
  const { setAuthenticated } = useContext(authContext);
  // console.log("location.state: ", location);

  const onChange = (e) => {
    setpayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onClick = async () => {
    try {
      setLoading(true);
      await Auth.confirmSignUp(
        location?.state?.email ?? payload.email,
        payload.verificationCode
      );
      setLoading(false);

      if (location?.state?.email && location?.state?.password) {
        await Auth.signIn(location.state.email, location.state.password);
        const userData = await Auth.currentAuthenticatedUser();
        setAuthenticated(userData.attributes);
        navigate("/query");
      } else {
        navigate("/login");
      }
    } catch (e) {
      setLoading(false);
      alert(e.message);
    }
  };

  return (
    <>
      <div className="login-page pb-5">
        {loading && (
          <div className="loader">
            <ClipLoader
              color={"#ffffff"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <Container>
          <Row>
            <Col md={12}>
              <div className="forms">
                <h1 className="py-5 mt-0 mt-md-5 mb-3">Verify email</h1>
                <Form className="login-form">
                  <Row className="form-inputs mx-auto py-4">
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        {!location?.state?.email && (
                          <>
                            <Form.Label className="pt-2">
                              Please enter your email address
                            </Form.Label>
                            <Form.Control
                              name="email"
                              type="email"
                              placeholder="E-email"
                              onChange={onChange}
                            />
                          </>
                        )}
                        <Form.Label className="pt-2">
                          Please enter the code sent to your email
                        </Form.Label>
                        <Form.Control
                          name="verificationCode"
                          type="text"
                          placeholder="Verification code"
                          onChange={onChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button
                        variant="primary"
                        className="form-btn mt-2"
                        onClick={onClick}
                      >
                        Verify
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
