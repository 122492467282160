import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { ClipLoader } from "react-spinners";
import Geocode from "react-geocode";
import TravelTeamsModal from "./travelTeamModal";
import mapMarker from "../assets/images/icons/map-marker.svg";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage("en");

const containerStyle = {
  width: "100%",
  height: "100vh",
  marginBottom: "80px",
};

const defaultCenter = {
  lat: 40.7128, // Default coordinates (change as per your requirement)
  lng: -74.006,
};

function MapWithMarkers({ markersData, groupedTeams }) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const handleMarkerClick = (marker) => {
    const item = groupedTeams[marker.city][marker.index];
    setSelectedItem(item);
    setModalShow(true);
  };

  if (loadError) {
    return <p>Map cannot be loaded right now.</p>;
  }

  return (
    <>
      <TravelTeamsModal
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
        setModalHandle={() => setModalShow(false)}
        item={selectedItem}
        id={selectedItem?.id}
        type={selectedItem?.type}
        cardTitleModal={selectedItem?.cardTitle}
        cardType={selectedItem?.cardType}
        minimumAge={selectedItem?.minimumAge}
        maximumAge={selectedItem?.maximumAge}
        city={selectedItem?.city}
        state={selectedItem?.state}
        coaches={selectedItem?.coaches}
        level={selectedItem?.level}
        gender={selectedItem?.gender}
      />
      {isLoaded ? (
        <GoogleMap
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={10}
          clickableIcons
        >
          {markersData.map((marker, index) => (
            <MarkerF
              key={"marker-" + index}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={() => handleMarkerClick(marker)}
              icon={mapMarker}
            />
          ))}
        </GoogleMap>
      ) : (
        <ClipLoader
          color={"#ff8e15"}
          loading={!isLoaded}
          size={32}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </>
  );
}

export default MapWithMarkers;
