import { Container, Row, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PopupModal } from "react-calendly";
import { useContext, useState } from "react";

import CreateAccountPopup from "./createAccountPopup";
import authContext from "../store/authContext";

export default function GameUpStreams(props) {
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [createPopupOpen, setCreatePopupOpen] = useState(false);
  const { authenticated } = useContext(authContext);
  const isTablet = window.matchMedia("(max-width: 991px)").matches;

  const openCreatePopup = () => setCreatePopupOpen(true);
  const closeCreatePopup = () => setCreatePopupOpen(false);
  const openCalendlyModal = () => setCalendlyModalOpen(true);

  return (
    <Container>
      <PopupModal
        url="https://calendly.com/game-up-team/game-up-call"
        onModalClose={() => setCalendlyModalOpen(false)}
        open={calendlyModalOpen}
        rootElement={document.getElementById("root")}
      />

      {!props.hideDescription && (
        <Row>
          <Col className="gmup">
            <p>
              gameup:{" "}
              <span style={{ color: props.color ? props.color : "white" }}>
                {" "}
                your youth basketball destination
              </span>
            </p>
          </Col>
        </Row>
      )}
      <Row className="gmup-list">
        <Col md={6} lg={3} className="gmup-item">
          <h5>Get in touch with a basketball concierge</h5>
          <p>
            Get in touch with a basketball concierge for one-on-one access with
            GameUp's knowledgeable staff!
          </p>
          <Link to="/basketball-concierge" className="jn-btn">
            learn more
          </Link>
        </Col>
        <Col md={6} lg={3} className="gmup-item">
          <h5>Schedule workout with a GameUp trainer</h5>
          <p>
            Book an appointment to have a registered GameUp trainer assess your
            child’s basketball level.
          </p>
          <a
            className="jn-btn"
            onClick={authenticated ? openCalendlyModal : openCreatePopup}
          >
            schedule workout
          </a>
        </Col>
        <Col md={6} lg={3} className="gmup-item">
          <h5>Take a skills assesment</h5>
          <p>
            Take your skills to the best and get your child assessed! Match your
            child with player of their level.
          </p>
          <a
            className="jn-btn"
            onClick={authenticated ? openCalendlyModal : openCreatePopup}
          >
            schedule workout
          </a>
        </Col>
        {isTablet ? (
          <>
            <Col md={6} lg={3} className="gmup-item">
              <h5>register here to become a gameup trainer</h5>
              <p>
                Want to appear in the search listing? Register here to become a
                GameUp trainer.
              </p>
              <Link
                to={"/trainer-signup"}
                className="jn-btn"
                onClick={(event)=>{
                  if (!authenticated){
                    event.preventDefault();
                    openCreatePopup();
                  }}
                }
              >
                Register now
              </Link>
            </Col>
            <Col md={6} lg={3} className="gmup-item">
              <h5>Add your team to the GameUp directory</h5>
              <p>
                Is your team missing from our directory? Add your team to the
                GameUp directory.
              </p>
              <Link
                to={"/new-team"}
                className="jn-btn"
                onClick={(event)=>{
                  if (!authenticated){
                    event.preventDefault();
                    openCreatePopup();
                  }}
                }
              >
                Register team
              </Link>
            </Col>
          </>
        ) : (
          <Col md={6} lg={3} className="gmup-small-container">
            <Row className="gmup-item-small">
              <h5>register to be a gameup trainer</h5>

              <Link
                to={"/trainer-signup"}
                className="jn-btn"
                onClick={(event)=>{
                  if (!authenticated){
                    event.preventDefault();
                    openCreatePopup();
                  }}
                }
              >
                Register now
              </Link>
            </Row>
            <Row className="gmup-item-small">
              <h5>Register your team with Gameup</h5>

              <Link
                to={"/new-team"}
                className="jn-btn"
                onClick={(event)=>{
                  if (!authenticated){
                    event.preventDefault();
                    openCreatePopup();
                  }}
                }
              >
                Register team
              </Link>
            </Row>
          </Col>
        )}
      </Row>
      {createPopupOpen && <CreateAccountPopup closePopup={closeCreatePopup} />}
    </Container>
  );
}
