import { Container, Row, Col, Card } from "react-bootstrap";
import AboutCardImg1 from "../assets/images/about-cards/about-us-1.png";
import AboutCardImg2 from "../assets/images/about-cards/about-us-2.png";
import AboutCardImg3 from "../assets/images/about-cards/about-us-3.png";
import TeamImg1 from "../assets/images/about-cards/team-1.png"
import TeamImg2 from "../assets/images/about-cards/team-2.png"
import TeamImg3 from "../assets/images/about-cards/team-3.png"
import TeamImg4 from "../assets/images/about-cards/team-4.png"
import TeamImg5 from "../assets/images/about-cards/team-5.png"
import TeamImg6 from "../assets/images/about-cards/team-6.png"
import LogoIcon from "../assets/images/logo-icon.svg";

import { tabTitle } from "../components/tabsTitle";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";

export default function About() {
  tabTitle("About Us");

  return (
    <>
      <div className="pt-0 pt-md-5 pb-0 pb-md-5 about-page-banner ni">
        <Container>
          <Row>
            <Col md={12} className="position-relative pt-0 pt-md-3 pb-3">
              <div className="forms pt-0 pt-md-3 pb-3 na">
                <h1 className="py-5">About Us</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="py-5 mb-5 mt-0 mt-md-5 abt-cnt">
        <Container>
          <Row className="ab_sec1">
            <Col md={8} xs={12}>
              <div className="fndr-cnt-lft">
                <h4>Founders Statement</h4>
                <div className="para-cnt">
                  <p>
                    As a veteran basketball mom, I understand the challenges
                    that parents face when trying to find the right sports
                    programs for their children. That's why I created GameUp -
                    to help parents navigate the maze of youth sports programs
                    and find the best options for their kids. Starting with
                    basketball, we will be rolling out guides for baseball,
                    soccer, tennis, and other sports in the near future.
                  </p>
                  <p>
                    When my own kids were growing up, I spent countless hours
                    researching sports teams and programs. I knew the importance
                    of finding the right fit for each child's needs and
                    interests. However, the process was often confusing and
                    frustrating, not to mention expensive. We've had our share
                    of unreliable coaches, disorganized teams, and organizations
                    that took our money without truly developing our children's
                    skills.
                  </p>
                  <p>
                    That's why I became more intentional in my research and data
                    collection. With GameUp, my goal is to provide all families
                    with a reliable resource that they can trust. We want to
                    make it easier for parents to find the best programs and
                    teams for their children, so they can focus on supporting
                    their kids on and off the court.
                  </p>
                  <p>
                    My own family has experienced the ups and downs of youth
                    sports. When my oldest child, Cole, became interested in
                    basketball at age 4, I didn't know where to start. As he
                    grew older and more advanced, I struggled to find programs
                    that matched his needs. Cole ended up trying every type of
                    basketball program in New York City, from beginner teams to
                    elite programs. He even went on to play college basketball
                    at the University of North Carolina and was eventually
                    drafted by the Orlando Magic.
                  </p>
                  <p>
                    When my daughter, Ella, started playing a few years later,
                    it was even harder to find quality programs for girls. She
                    played for various AAU teams in the tri-state area before
                    winning her high school state championship and being named
                    New York State co-player of the year in 2020.
                  </p>
                  <p>
                    By the time my youngest child, Leo, began competing, I had
                    finally found my way through the youth basketball maze. All
                    of the research I had done in the past made it much easier
                    to find the right programs for Leo. He even won the 2022
                    third grade AAU world championship!
                    <br />
                    As a parent, I know how much we all want the best
                    opportunities for our children. GameUp is here to save you
                    time, money, and effort, while helping your child become
                    their absolute best on and off the court.
                  </p>
                </div>
                <h6>Welcome to GameUp!</h6>
                <Row className="founder-by-section align-items-center fn">
                  <Col md={2} xs={12} className="me-md-0 me-3 fundr">
                    <img src={LogoIcon} alt="founderImage" />
                  </Col>
                  <Col md={8} xs={12} className="ms-3 pt-2 ps-4 ps-md-0">
                    <h3 className="mb-0 mb-md-2">Founded by</h3>
                    <h5>
                      Crystal McCrary <br />
                      &amp; cole anthony
                    </h5>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4} xs={12} className="abt-sec-rht">
              <Col md={12} xs={4} className="about-us-description about-card">
                <div className="aboutus-cards">
                  <Card style={{ alignItems: "center", border: "none" }}>
                    <img
                      className="w-100"
                      src={AboutCardImg3}
                      alt="players_img"
                    />
                  </Card>
                </div>
              </Col>
              <Col md={12} xs={4} className="about-us-description about-card">
                <div className="aboutus-cards">
                  <Card style={{ alignItems: "center", border: "none" }}>
                    <img
                      className="w-100"
                      src={AboutCardImg2}
                      alt="players_img"
                    />
                  </Card>
                </div>
              </Col>
              <Col md={12} xs={4} className="about-us-description about-card">
                <div className="aboutus-cards">
                  <Card style={{ alignItems: "center", border: "none" }}>
                    <img
                      className="w-100"
                      src={AboutCardImg1}
                      alt="players_img"
                    />
                  </Card>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="team-main">
        <h4>
          Meet the <span>GameUp</span> team
        </h4>
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={4} md={6} xs={12}>
              <div>
                <Card className="team-card">
                  <div className="team-item">
                    <div className="team-mem">
                      <img src={TeamImg1} alt="image" />
                    </div>
                    <h6>Crystal McCrary</h6>
                    <span>Founder</span>
                    <small>NYTimes Bestselling Author</small>
                    <p>
                      Creator of Nickelodeon Little Ballers franchise, Co-author
                      of HOMECOURT ADVANTAGE
                    </p>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <div>
                <Card className="team-card">
                  <div className="team-item">
                    <div className="team-mem">
                      <img src={TeamImg2} alt="image" />
                    </div>
                    <h6>Cole Anthony</h6>
                    <span>Co-Founder</span>
                    <small>Point Guard Orlando Magic</small>
                    <p>
                      Orlando Magic
                      <br />
                      50 Ways Foundation President
                    </p>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12} className="text-center">
              <div>
                <Card className="team-card">
                  <div className="team-item ti2">
                    <div className="team-mem">
                      <img src={TeamImg6} alt="image" />
                    </div>
                    <h6>Kelvin Gardner</h6>
                    <span>Chief Operating Officer</span>
                    <small>
                      Master’s Degree in Business Administration, UCLA Anderson
                      <br /> Bachelor’s Degree in Finance
                      <br /> University of Oklahoma
                    </small>
                    <p>
                      Strategy and Business Development at Universal Pictures
                      previously worked at Lionsgate Entertainment
                    </p>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-md-center">
            <Col lg={4} md={6} xs={12}>
              <div>
                <Card className="team-card">
                  <div className="team-item ti2">
                    <div className="team-mem">
                      <img src={TeamImg3} alt="image" />
                    </div>
                    <h6>Kenton Blacutt</h6>
                    <span>Technical Advisor</span>
                    <small>
                      Bachelor’s Degree in Data Science w/ Computer Science
                      <br />
                      New York University
                    </small>
                    <p>
                      Founder and CEO of Vax Pass and Kikt, Cloud App Developer
                      working at Amazon Web Services
                    </p>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <div>
                <Card className="team-card">
                  <div className="team-item ti2">
                    <div className="team-mem">
                      <img src={TeamImg4} alt="image" />
                    </div>
                    <h6>Bristol Fales-Hill</h6>
                    <span>Senior Design Team Strategist </span>
                    <small>
                      Bachelor’s Degree in Computer Science
                      <br /> Harvard
                    </small>

                    <p className="ex-p">
                      Currently studying Computer Science and Economics,
                      previously interned at Dartmouth and Emblematic Group
                    </p>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <div>
                <Card className="team-card">
                  <div className="team-item ti2">
                    <div className="team-mem">
                      <img src={TeamImg5} alt="image" />
                    </div>
                    <h6>Bryce Council</h6>
                    <span>
                      Entrepeneur & Philanthropist
                      <br />
                      content advisor
                    </span>
                    <p>
                      50 Ways Foundation Co Founder
                      <br />
                      Cole Anthony Business Manager
                    </p>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ChatbotFloatingButton />
    </>
  );
}
