import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Storage } from "aws-amplify";
import Card from "react-bootstrap/Card";
import ImagePlaceholder from "../assets/images/f-prfl-img.svg";
import TrainerDetailModal from "./trainerDetailModal";

const CONCIERGE_CALENDLY_LINK = "https://calendly.com/game-up-team/game-up-call";

export default function TrainerCard(props) {
  const navigate = useNavigate();
  const [modalHandle, setModalHandle] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleModal = () => {
    if (props?.noModal !== "noModal") {
      setModalHandle(!modalHandle);
    } else {
      navigate(`/player-dash`);
    }
  };
  const fetchTrainerImage = async (imageId) => {
    if (!imageId) return;

    const url = await Storage.get(imageId);

    setImageUrl(url);
  };

  useEffect(() => {
    if (props.cardImage) {
      fetchTrainerImage(props.cardImage);
    }
  }, []);

  return (
    <>
      <TrainerDetailModal
        modalShow={modalHandle}
        setModalHandle={setModalHandle}
        onHide={handleModal}
        website={props?.website}
        cardTitleModal={props?.cardTitle}
        cardType={props?.cardType}
        city={props?.city}
        state={props?.state}
        email={props?.email}
        company={props?.company}
        phoneNumber={props?.phoneNumber}
        item={props?.item}
        socialMediaHandles={props?.socialMediaHandles}
        profileImage={imageUrl ?? ImagePlaceholder}
        calendly={props?.calendly  ?? CONCIERGE_CALENDLY_LINK}
      />
      <Card
        className={
          props?.imageAlignment
            ? `cards-layout ${props?.imageAlignment}`
            : "cards-layout"
        }
        onClick={handleModal}
      >
        <Card.Title>
          <img
            className="trainer-img"
            style={{
              width: 105,
              height: 105,
              borderRadius: "50%",
              objectFit: "cover",
              padding: 0,
            }}
            src={imageUrl ?? ImagePlaceholder}
            alt={props.cardTitle}
          />
        </Card.Title>
        <Card.Body>
          <h6>{props?.cardTitle}</h6>
          {props?.cardSubTitle &&
            props?.cardSubTitle !== ", " &&
            props?.cardSubTitle !== "null, null" && (
              <p className="pt-2">{props?.cardSubTitle}</p>
            )}
        </Card.Body>
      </Card>
    </>
  );
}
