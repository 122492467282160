import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserIcon from "../assets/images/icons/user-black-icon.svg";
import CheckIcon from "../assets/images/icons/check-icon.svg";
import DenyReasonPopup from "../components/denyReasonPopup";
import { tabTitle } from "../components/tabsTitle";

export default function AdminSettings() {
  tabTitle("Admin Settings");
  const [denyOpen, setDenyOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamToDenyId, setTeamToDenyId] = useState("");

  const openPopup = () => setDenyOpen(true);
  const closePopup = () => setDenyOpen(false);

  const fetchTeams = async () => {
    try {
      const input = {
        mode: "cors",
      };
      const { teams: _teams } = await fetch(
        "https://1h3m5ddhnl.execute-api.us-east-1.amazonaws.com/prod/teams",
        input
      ).then((response) => response.json());

      setTeams(_teams);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDenyClick = (teamId) => {
    setTeamToDenyId(teamId);
    openPopup();
  };

  const handleApproval = async (teamId, isApproved, reason) => {
    try {
      const input = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: teamId,
          isApproved,
          reason,
        }),
        mode: "cors",
      };

      const res = await fetch(
        "https://1h3m5ddhnl.execute-api.us-east-1.amazonaws.com/prod/update",
        input
      );

      if (!res.ok) {
        throw new Error(res.body);
      }
    } catch (e) {
      alert("Error while handling team approval");
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <div id="wrapper">
      <div className="admin-stngs-main">
        <Container>
          <Row>
            <Col md={12} className="admin-hd">
              <h2>Admin Settings</h2>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="admin-stngs-cnt">
        <Container>
          <Row className="admin-list">
            <Col md={4} className="admin-lft">
              <div className="ad-hd">
                <Row className="ad-list">
                  <Col className="ad-hd-lft">
                    <img src={UserIcon} alt="image" />
                    <p>Account</p>
                  </Col>
                  <Col>
                    <a href="#">Edit</a>
                  </Col>
                </Row>
              </div>
              <Row className="ad-cnt">
                <Col md={12} className="ad-cnt-lst">
                  <div className="nm-div">
                    <p>Name</p>
                    <span>Admin Account</span>
                  </div>
                  <div className="adrs">
                    <p>address</p>
                    <span>Address Line 1</span>
                    <span>Address Line 2</span>
                    <span>City, ST 12345</span>
                  </div>
                  <div className="em-div">
                    <p>email address</p>
                    <span>admin@gameup.fyi</span>
                  </div>
                  <div className="pwd-div">
                    <p>Password</p>
                    <span>***************</span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={7} className="admin-rht">
              <Row className="tb-data-hd">
                <Col className="aprv-div">
                  <img src={CheckIcon} alt="image" />
                  <p>Pending Approval</p>
                </Col>
              </Row>

              <div className="data-table-responsive">
                <div className="table-hd">
                  <Row>
                    <Col className="table-ul">
                      <div className="mb-l">
                        <p>Team Name</p>
                        <p>Location</p>
                        <p>Category/Type</p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="table-cnt">
                  {teams.map((team, idx) => (
                    <Row key={team.id} data-test={`team-item-${idx + 1}`}>
                      <Col className="table-ul">
                        <div className="mb-l">
                          <p>{team.name}</p>
                          <p>New York, NY</p>
                          <p>{team.teamType}</p>
                        </div>
                        <div className="mb-r">
                          <p>
                            <div className="ap-dny">
                              <button
                                href="#"
                                className="aprv"
                                onClick={() => handleApproval(team.id, true)}
                              >
                                approve
                              </button>
                              <button
                                className="dny"
                                onClick={() => handleDenyClick(team.id)}
                              >
                                Deny
                              </button>
                            </div>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {denyOpen && (
        <DenyReasonPopup
          closePopup={closePopup}
          handleApproval={handleApproval}
          teamToDenyId={teamToDenyId}
        />
      )}
    </div>
  );
}
