import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import TravelTeamsModal from "./travelTeamModal";
import { useNavigate } from "react-router-dom";

export default function Cards(props) {
  const navigate = useNavigate();
  const [modalHandle, setModalHandle] = useState(false);
  const handleModal = () => {
    if (props?.noModal !== "noModal") {
      setModalHandle(!modalHandle);
    } else {
      navigate(`/player-dash`);
    }
  };
  return (
    <>
      <TravelTeamsModal
        modalShow={modalHandle}
        setModalHandle={setModalHandle}
        onHide={handleModal}
        id={props?.id}
        type={props?.type}
        website={props?.website}
        cardTitleModal={props?.cardTitle}
        cardType={props?.cardType}
        minimumAge={props?.minimumAge}
        maximumAge={props?.maximumAge}
        city={props?.city}
        state={props?.state}
        coaches={props?.coaches}
        level={props?.level}
        item={props?.item}
        gender={props?.gender}
      />
      <Card
        className={
          props?.imageAlignment
            ? `cards-layout ${props?.imageAlignment}`
            : "cards-layout"
        }
        onClick={handleModal}
      >
        <Card.Title>
          <img src={props?.cardImage} className="card-img" alt="cards_img" />
        </Card.Title>
        <Card.Body>
          <h6>{props?.cardTitle}</h6>
          {props?.cardSubTitle &&
            props?.cardSubTitle !== ", " &&
            props?.cardSubTitle !== "null, null" && (
              <p className="pt-2">{props?.cardSubTitle}</p>
            )}
        </Card.Body>
      </Card>
    </>
  );
}
