/* eslint-disable no-useless-concat */
import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Logo from "../assets/images/logo.png";
import UserIcon from "../assets/images/icons/user-icon.svg";
import authContext from "../store/authContext";
import useClickOutside from "../hooks/useClickOutside";
import useKeyboardPress from "../hooks/useKeyboardPress";

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authenticated, setAuthenticated } = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useClickOutside(() => setIsMenuOpen(false));
  useKeyboardPress(() => setIsMenuOpen(false), ["Escape"]);

  const [navIcon, setNavIcon] = useState(false);

  const navIconToggle = () => {
    setNavIcon(!navIcon);
  };
  const navItemsTrigger = () => {
    if (window.screen.width <= 991) {
      document.getElementsByClassName("navbar-toggler")[0].click();
    }
  };

  const logout = async () => {
    setLoading(true);
    await Auth.signOut();
    setAuthenticated(null);
    navigate("/");
    setLoading(false);
    window.location.reload();
  };

  const login = async () => {
    setLoading(true);
    await Auth.signOut();
    navigate("/login");
    setLoading(false);
  };

  return (
    <>
      <div>
        {loading && (
          <div className="loader">
            <ClipLoader
              color={"#ffffff"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <Navbar
          bg="dark"
          expand="lg"
          className={navIcon ? "nav-active-custom" : "" + "py-3"}
        >
          <Container>
            <Link to="/" style={{ cursor: "pointer" }}>
              <img className="logo" src={Logo} alt="Logo" />
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={navIconToggle}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="me-auto d-flex justify-content-between align-items-center w-100 px-5 text-uppercase"
                onClick={navItemsTrigger}
              >
                <div className="d-flex">
                  {/* <Link style={{ marginLeft: 20 }} to="/search">
                    Search
                  </Link> */}
                  {authenticated && <Link to="/query">Search</Link>}
                  <Link style={{ marginLeft: 20 }} to="/basketball-concierge">
                    Basketball Concierge
                  </Link>
                  <Link style={{ marginLeft: 20 }} to="/chatbot">
                    Chat
                  </Link>
                  <Link style={{ marginLeft: 20 }} to="/about">
                    About Us
                  </Link>
                </div>
                <div className="login-signup">
                  {authenticated ? (
                    <div className="user-icon-container">
                      <img
                        src={UserIcon}
                        onClick={() => setIsMenuOpen((prev) => !prev)}
                        data-test="user-menu"
                      />
                      {isMenuOpen && (
                        <div ref={menuRef} className="user-icon-menu text-capitalize">
                          <ul>
                            {/* <li>
                              <Link
                                to="/profile"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                My Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/account-settings"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Account settings
                              </Link>
                            </li> */}
                            <li>
                              <button onClick={logout}>Log Out</button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Button
                      variant="fdsdy"
                      className="login-btn px-md-5 px-3 py-2"
                      onClick={login}
                      data-test="login-button"
                    >
                      Login
                    </Button>
                  )}

                  {/* <Link to="/login" className="login-btn px-md-5 px-3 py-2">
                    {location.pathname === "/query"
                      ? "Log Out"
                      : location.pathname === "/results"
                      ? "Log Out"
                      : location.pathname === "/profile"
                      ? "Log Out"
                      : location.pathname === "/player-dash"
                      ? "Log Out"
                      : "log in"}
                  </Link> */}
                  {!authenticated && (
                    <Link
                      to="/joingame"
                      className="joingame-btn px-md-5 px-3 py-2 ms-3"
                    >
                      Join Gameup
                    </Link>
                  )}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
