// import React from "react";
import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ClipLoader } from "react-spinners";

import EditModalImg from "../assets/images/modal/editTeamImg.png";
import { updateAAUTeam } from "../graphql/mutations";

export default function EditTeamModal(props) {
  const [team, setTeam] = useState({});
  const [teamName, setTeamName] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTeamName(props?.team?.name);
    setTeam(props?.team);
    props?.team?.banner && getBannerImage(props?.team?.banner);
  }, [props]);

  const getBannerImage = async (picture) => {
    try {
      const imageAccessURL = await Storage.get(picture);
      setBannerImage(imageAccessURL);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdate = async (banner) => {
    try {
      const updateAAUTeamData = await API.graphql({
        query: updateAAUTeam,
        variables: {
          input: {
            id: team.id,
            name: teamName,
            ranking: team.ranking,
            banner,
            _version: team._version,
          },
        },
      });
      if (updateAAUTeamData?.data?.updateAAUTeam) {
        props.update();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const uploadPic = async () => {
    try {
      setLoading(true);
      props.onHide();
      const result = await Storage.put(
        `team_${new Date().getTime()}.${selectedFile?.name.split(".")[1]}`,
        selectedFile,
        {
          contentType: selectedFile.type,
          acl: "public-read",
        }
      );
      await onUpdate(result.key);
      setSelectedFile(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <ClipLoader
            color={"#ffffff"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h1 className="modal-heading">edit team</h1>
          <div className="upload-img-section">
            <div className="show-img-modal">
              <img
                src={bannerImage ? bannerImage : EditModalImg}
                alt="Banner"
              />
            </div>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => {
                  setSelectedFile(e.target.files[0]);
                  setBannerImage(URL.createObjectURL(e.target.files[0]));
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <label className="custom-file-label" htmlFor="customFile">
                upload banner image Remove
              </label>
              <p onClick={() => setBannerImage(null)}>Remove</p>
            </div>
          </div>
          <div className="team-name-section">
            <label htmlFor="">Team Name</label>
            <input
              className="modal-input"
              type="text"
              placeholder="Riverside Hawks"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
          <div className="modal-btns">
            <button
              className="updateBtn"
              onClick={() => {
                selectedFile ? uploadPic() : onUpdate(team.banner);
              }}
              type="submit"
            >
              Update
            </button>
            <button
              className="cancelBtn"
              onClick={() => {
                props.onHide();
                setSelectedFile(null);
              }}
              type=""
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
