import LogoIcon from "../assets/images/logo-icon.svg";

export default function ChatLoadingServer() {
  return (
    <div className="d-flex align-items-center gap-4">
      <div className="loading-server">
        <img src={LogoIcon} />
      </div>
      <div className="dot-flashing"></div>
    </div>
  );
}
