import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ClipLoader } from "react-spinners";
import { API, Storage } from "aws-amplify";

import AddCoach from "../assets/images/coaches/addcoach.png";

import { createCoach, updateCoach } from "../graphql/mutations";

export default function AddCoachModal(props) {
  const [coach, setCoach] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCoach({
      id: props?.coach?.id,
      name: props?.coach?.name || "",
      costOfTraining: props?.coach?.costOfTraining || "",
      email: props?.coach?.email || "",
      userName: props?.coach?.userName || "",
      avatar: props?.coach?.avatar,
      _version: props?.coach?._version || 1,
    });
    props?.coach?.avatar && getAvatarImage(props?.coach?.avatar);
  }, [props]);

  const getAvatarImage = async (picture) => {
    try {
      const imageAccessURL = await Storage.get(picture);
      setCoach({
        ...props?.coach,
        avatar: imageAccessURL,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const onCreate = async () => {
    try {
      setLoading(true);
      let payload = {
        name: coach?.name,
        teamID: props?.teamID,
        costOfTraining: String(coach?.costOfTraining),
        email: coach?.email,
        userName: coach?.userName,
      };
      if (selectedFile) {
        const result = await Storage.put(
          `coach_${new Date().getTime()}.${selectedFile?.name.split(".")[1]}`,
          selectedFile,
          {
            level: "public",
            contentType: selectedFile.type,
          }
        );
        payload.avatar = result.key;
        setSelectedFile(null);
      }

      const createCoachData = await API.graphql({
        query: createCoach,
        variables: {
          input: payload,
        },
      });
      setLoading(false);
      if (createCoachData?.data?.createCoach) {
        props?.addUpdate();
      }
    } catch (error) {
      setLoading(false);
      console.log("error in create coach: ", error);
    }
  };

  const onUpdate = async () => {
    try {
      setLoading(true);
      let payload = {
        id: coach?.id,
        name: coach?.name,
        teamID: props?.teamID,
        costOfTraining: String(coach?.costOfTraining),
        email: coach?.email,
        userName: coach?.userName,
        _version: coach?._version,
      };
      if (coach?.avatar === "") {
        payload.avatar = "";
      }
      if (selectedFile) {
        const result = await Storage.put(
          `coach_${new Date().getTime()}.${selectedFile?.name.split(".")[1]}`,
          selectedFile,
          {
            level: "public",
            contentType: selectedFile.type,
          }
        );
        payload.avatar = result.key;
        setSelectedFile(null);
      }
      const updateCoachData = await API.graphql({
        query: updateCoach,
        variables: {
          input: payload,
        },
      });
      setLoading(false);
      if (updateCoachData?.data?.updateCoach) {
        props?.addUpdate();
      }
    } catch (error) {
      setLoading(false);
      console.log("error in update coach:", error);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <ClipLoader
            color={"#ffffff"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h1 className="modal-heading">
            {Object.keys(props?.coach).length > 0
              ? "Edit a coach"
              : "Add a coach"}
          </h1>
          <div className="upload-img-section">
            <div className="coach-img-modal">
              <img
                src={coach?.avatar ? coach?.avatar : AddCoach}
                alt="Coachimage"
              />
            </div>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => {
                  setSelectedFile(e.target.files[0]);
                  setCoach({
                    ...coach,
                    avatar: URL.createObjectURL(e.target.files[0]),
                  });
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <label className="custom-file-label" htmlFor="customFile">
                upload banner image Remove
              </label>
              <p
                onClick={() =>
                  setCoach({
                    ...coach,
                    avatar: "",
                  })
                }
              >
                Remove
              </p>
            </div>
          </div>
          <div className="coach-modal-info">
            <div>
              <label htmlFor="">Team Name</label>
              <input
                className="modal-input"
                type="text"
                placeholder="First and Last Name"
                name="name"
                id="name"
                value={coach?.name}
                onChange={(e) =>
                  setCoach({
                    ...coach,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="">Email Address</label>
              <input
                className="modal-input"
                type="email"
                placeholder="example@email.com"
                name="email"
                id="email"
                value={coach?.email}
                onChange={(e) =>
                  setCoach({
                    ...coach,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="">Cost of Training</label>
              <input
                className="modal-input"
                type="number"
                placeholder="$"
                name="costOfTraining"
                id="costOfTraining"
                value={coach?.costOfTraining}
                onChange={(e) =>
                  setCoach({
                    ...coach,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="">GameUp Username</label>
              <input
                className="modal-input"
                type="text"
                placeholder="@"
                name="userName"
                id="userName"
                value={coach?.userName}
                onChange={(e) =>
                  setCoach({
                    ...coach,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="coach-btn-section">
            <div className="modal-btns coach-btns">
              <button
                className="updateBtn coachUpdate"
                onClick={() => {
                  Object.keys(props?.coach).length > 0
                    ? onUpdate()
                    : onCreate();
                }}
                type="submit"
              >
                {Object.keys(props?.coach).length > 0
                  ? "Edit coach"
                  : "Add coach"}
              </button>
              <button
                className="cancelBtn coachCancel"
                onClick={props.onHide}
                type=""
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
