import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { ClipLoader } from "react-spinners";
import Geocode from "react-geocode";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import UserWhiteIcon from "../assets/images/icons/user-white-icon.svg";
import Footer from "../components/footer";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage("en");

const FORM_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  company: "",
  phoneNumber: "",
  socialMediaHandles: "",
  ageGroup: "",
  website: "",
  reference: "",
  relevantExperience: "",
  profileImage: "",
  resume: "",
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  city: yup.string().required("Type a valid ZIP code to populate city"),
  state: yup.string().required("Type a valid ZIP code to populate state"),
  zipcode: yup
    .string()
    .required("Zipcode is required")
    .matches(/^\d{5}$/, "Zipcode must be 5 digits"),
  email: yup.string().required("Email is required").email("Invalid email"),
  company: yup.string().notRequired(),
  phoneNumber: yup.string().required("Phone number is required"),
  socialMediaHandles: yup
    .string()
    .required("Social media handles are required"),
  ageGroup: yup.string().required("Age group is required"),
  website: yup.string().required("Website is required"),
  reference: yup.string().required("Reference is required"),
  relevantExperience: yup.string().required("Relevant experience is required"),
  profileImage: yup.string().notRequired(),
  resume: yup.string().notRequired(),
});

export default function TrainerSignUp() {
  const footerColor = "rgb(255 255 255 / 80%)";

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resumeFileName, setResumeFileName] = useState("");
  const resumeInputRef = useRef(null);
  const profileImageInputRef = useRef(null);
  const [isUpdatingResume, setIsUpdatingResume] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: FORM_INITIAL_VALUES,
    resolver: yupResolver(validationSchema),
  });
  const watchZipcode = watch("zipcode");
  const watchProfileImage = watch("profileImage");

  const handleResumeFileChange = async () => {
    setIsUpdatingResume(true);
    if (resumeInputRef.current?.files) {
      const file = resumeInputRef.current.files[0];
      const key = `uploads/${Date.now()}-${file.name}`;

      try {
        await Storage.put(key, file);
        setResumeFileName(file.name);
        setValue("resume", key);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
    setIsUpdatingResume(false);
  };

  const handleProfileImageFileChange = async () => {
    if (profileImageInputRef.current?.files) {
      const file = profileImageInputRef.current.files[0];
      const key = `uploads/${Date.now()}-${file.name}`;

      try {
        await Storage.put(key, file, {
          level: "public",
        });
        // setResumeFileName(file.name);
        setValue("profileImage", key);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  const handleOpenResumeFileInput = () => {
    resumeInputRef.current?.click();
  };

  const handleOpenProfileImageFileInput = () => {
    profileImageInputRef.current?.click();
  };

  const handleRemoveResume = async () => {
    setIsUpdatingResume(true);
    try {
      await Storage.remove(getValues("resume"));
      setResumeFileName("");
      setValue("resume", "");

      resumeInputRef.current.value = null;
    } catch (error) {
      console.log("Error removing file: ", error);
    }
    setIsUpdatingResume(false);
  };

  const handleRemoveProfileImage = async () => {
    if (!getValues("profileImage")) return;

    try {
      await Storage.remove(getValues("profileImage"));
      setProfileImageURL("");
      setValue("profileImage", "");

      profileImageInputRef.current.value = null;
    } catch (error) {
      console.log("Error removing file: ", error);
    }
  };

  const getProfileImageURL = async (key) => {
    if (!key) return null;
    try {
      const url = await Storage.get(key);
      return url;
    } catch (error) {
      console.log("Error getting profile image URL:", error);
      return null;
    }
  };

  const [profileImageURL, setProfileImageURL] = useState(null);

  const handleGetAddressFromZipCode = (zipcode) => {
    Geocode.fromAddress(zipcode.concat(" USA")).then((response) => {
      let city, state;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
            case "sublocality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      setValue("city", city);
      setValue("state", state);
      setError("city", {
        message: city ? "" : "Type a valid ZIP code to populate city",
      });
      setError("state", {
        message: state ? "" : "Type a valid ZIP code to populate state",
      });
    });
  };

  useEffect(() => {
    const isValidZipCode = watchZipcode.length === 5 && !isNaN(watchZipcode);
    if (isValidZipCode) {
      handleGetAddressFromZipCode(watchZipcode);
    } else {
      setValue("city", "");
      setValue("state", "");

      if (touchedFields.zipcode) {
        setError("city", { message: "Type a valid ZIP code to populate city" });
        setError("state", {
          message: "Type a valid ZIP code to populate state",
        });
      }
    }
  }, [watchZipcode]);

  useEffect(() => {
    const fetchProfileImageURL = async () => {
      const url = await getProfileImageURL(watchProfileImage);
      setProfileImageURL(url);
    };

    fetchProfileImageURL();
  }, [watchProfileImage]);

  const onSubmit = async (values, e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const input = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
        mode: window?.Cypress ? "cors" : "no-cors",
      };

      const res = await fetch(
        "https://mmz49mzmqe.execute-api.us-east-1.amazonaws.com/prod/",
        input
      );

      if (!res.ok) {
        throw new Error(res.body);
      }

      setIsSubmitting(false);
      navigate("/confirmation", {
        state: {
          type: "trainer",
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
        },
      });
    } catch (error) {
      console.error("Error registering trainer:", error);
      setIsSubmitting(false);
      alert("Error registering trainer", error);
    }
  };

  return (
    <div id="wrapper">
      <div className="trnr-snup">
        <Container>
          <Row>
            <Col md={12} className="trnr-cnt">
              <h2>Register here to become a Gameup trainer</h2>
              <Form
                id="trainer-signup-form"
                onSubmit={handleSubmit(onSubmit)}
                className="r-form"
              >
                <Row className="f-prfl">
                  <Col className="f-prfl-lft">
                    <img src={profileImageURL ?? UserWhiteIcon} alt="image" />
                    <input
                      id="profileImage"
                      type="file"
                      ref={profileImageInputRef}
                      onChange={handleProfileImageFileChange}
                      name="profileImage"
                      style={{ display: "none" }}
                      data-test="profile-image-input"
                    />
                  </Col>
                  <Col className="f-prfl-rht">
                    <p
                      role="button"
                      className="cursor-pointer"
                      onClick={handleOpenProfileImageFileInput}
                      data-test="upload-profile-image-button"
                    >
                      upload Profile image
                    </p>
                    <button
                      type="button"
                      onClick={handleRemoveProfileImage}
                      data-test="remove-profile-image-button"
                    >
                      Remove
                    </button>
                  </Col>
                </Row>
                <Row className="r-form-list">
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        isInvalid={!!errors.firstName}
                        {...register("firstName")}
                        data-test="first-name-input"
                      />
                    </Form.Group>
                    {errors.firstName && (
                      <small className="text-danger align-text-start">
                        {errors.firstName.message}
                      </small>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        isInvalid={!!errors.lastName}
                        {...register("lastName")}
                        data-test="last-name-input"
                      />
                    </Form.Group>
                    {errors.lastName && (
                      <small className="text-danger align-text-start">
                        {errors.lastName.message}
                      </small>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Your email</Form.Label>
                      <Form.Control
                        placeholder="example@email.com"
                        isInvalid={!!errors.email}
                        {...register("email")}
                        data-test="email-input"
                      />
                    </Form.Group>
                    {errors.email && (
                      <small className="text-danger align-text-start">
                        {errors.email.message}
                      </small>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Your ZIP Code</Form.Label>
                      <Form.Control
                        placeholder="ex: 12345"
                        isInvalid={!!errors.zipcode}
                        {...register("zipcode")}
                        maxLength={5}
                        data-test="zipcode-input"
                      />
                    </Form.Group>
                    {errors.zipcode && (
                      <small className="text-danger align-text-start">
                        {errors.zipcode.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Your City</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.city?.message}
                        {...register("city")}
                        disabled
                        data-test="city-input"
                      />
                    </Form.Group>
                    {errors.city && (
                      <small className="text-danger align-text-start">
                        {errors.city.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Your State</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.state?.message}
                        {...register("state")}
                        disabled
                        data-test="state-input"
                      />
                    </Form.Group>
                    {errors.state && (
                      <small className="text-danger align-text-start">
                        {errors.state.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        placeholder="(123) 456-7890"
                        isInvalid={!!errors.phoneNumber}
                        {...register("phoneNumber")}
                        data-test="phone-number-input"
                      />
                    </Form.Group>
                    {errors.phoneNumber && (
                      <small className="text-danger align-text-start">
                        {errors.phoneNumber.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Social media handles</Form.Label>
                      <Form.Control
                        placeholder="ex: Twitter: @cooltrainer-1"
                        isInvalid={!!errors.socialMediaHandles}
                        {...register("socialMediaHandles")}
                        data-test="social-media-handles-input"
                      />
                    </Form.Group>
                    {errors.socialMediaHandles && (
                      <small className="text-danger align-text-start">
                        {errors.socialMediaHandles.message}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row className="r-form-item adrs">
                  <Col className="r-form-lft">
                    <Col className="d-flex flex-column align-items-start">
                      <Form.Group className="align-self-stretch">
                        <Form.Label>Age group you train</Form.Label>
                        <Form.Select
                          className="web-link"
                          name="ageGroup"
                          isInvalid={!!errors.ageGroup}
                          {...register("ageGroup")}
                          data-test="age-group-input"
                        >
                          <option value="">Select</option>
                          <option value="1">4-8</option>
                          <option value="2">10-14</option>
                          <option value="3">14-18</option>
                          <option value="4">All</option>
                        </Form.Select>
                      </Form.Group>

                      {errors.ageGroup && (
                        <small className="text-danger align-text-start">
                          {errors.ageGroup.message}
                        </small>
                      )}
                    </Col>

                    <Col className="d-flex flex-column align-items-start">
                      <Form.Group className="align-self-stretch">
                        <Form.Label>Link to website/instagram</Form.Label>
                        <Form.Control
                          name="website"
                          type="text"
                          placeholder="ex: http://instagram.com/bball1"
                          isInvalid={!!errors.website}
                          {...register("website")}
                          data-test="website-input"
                        />
                      </Form.Group>
                      {errors.website && (
                        <small className="text-danger align-text-start">
                          {errors.website.message}
                        </small>
                      )}
                    </Col>
                  </Col>
                  <Col className="r-form-rht">
                    <Col className="d-flex flex-column align-items-start">
                      <Form.Group className="align-self-stretch">
                        <Form.Label>Relevant experience</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="relevantExperience"
                          placeholder="Enter relevant experience here..."
                          isInvalid={!!errors.relevantExperience}
                          {...register("relevantExperience")}
                          data-test="relevant-experience-input"
                        ></Form.Control>
                      </Form.Group>
                      {errors.relevantExperience && (
                        <small className="text-danger align-text-start">
                          {errors.relevantExperience.message}
                        </small>
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row className="r-form-list">
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Reference</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.reference}
                        {...register("reference")}
                        data-test="reference-input"
                      />
                    </Form.Group>
                    {errors.reference && (
                      <small className="text-danger align-text-start">
                        {errors.reference.message}
                      </small>
                    )}
                  </Col>

                  <Col md={6} className="r-form-item">
                    <Form.Group>
                      <Form.Label tabIndex="0">
                        Upload resume (optional)
                      </Form.Label>

                      <div className="resume-container">
                        <Form.Control
                          id="resume"
                          type="file"
                          ref={resumeInputRef}
                          onChange={handleResumeFileChange}
                          name="resume"
                          style={{ display: "none" }}
                          data-test="resume-input"
                        />
                        <Form.Control
                          type="text"
                          placeholder="Upload resume (PDF, DOC, PNG)"
                          value={resumeFileName}
                        />
                        {isUpdatingResume ? (
                          <ClipLoader
                            color={"#ff8e15"}
                            loading={isUpdatingResume}
                            size={24}
                            className="loading-spinner"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        ) : (
                          <button
                            type="button"
                            data-test="resume-modify-button"
                            onClick={
                              getValues("resume")
                                ? handleRemoveResume
                                : handleOpenResumeFileInput
                            }
                          >
                            {getValues("resume") ? "Remove" : "Upload"}
                          </button>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="t-btn">
                  <Col md={12} className="f-prfl-btns">
                    <Button
                      variant="primary"
                      type="submit"
                      className="cncl-btn"
                    >
                      cancel
                    </Button>
                    <Button
                      form="trainer-signup-form"
                      variant="primary"
                      type="submit"
                      className="go-btn"
                      data-test="submit-button"
                    >
                      Let’s Go!
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <ChatbotFloatingButton />
        <Footer color={footerColor} />
      </div>
    </div>
  );
}
