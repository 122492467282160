import { useState } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import PremiumIcon from "../assets/images/icons/premium-icon.svg";
import { tabTitle } from "../components/tabsTitle";
import fbImg from "../assets/images/btn_fb_signin.png";
import googleImg from "../assets/images/btn_google_signin.png";
import Footer from "../components/footer";

export default function JoinGame() {
  const navigate = useNavigate();
  tabTitle("Registration");
  const [payload, setpayload] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setpayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onClick = async () => {
    if (payload.password !== payload.confirmPassword) {
      alert("Passwords must match");

      return;
    }

    try {
      setLoading(true);
      const signUp = await Auth.signUp({
        username: payload.username,
        password: payload.password,
        attributes: {
          email: payload.username,
          name: payload.name,
        },
      });
      console.log("signUp: ", signUp);
      if (signUp) {
        setLoading(false);
        console.log("payload.username: ", payload.username);
        navigate("/verify", {
          state: { email: payload.username, password: payload.password },
        });
      }
    } catch (e) {
      setLoading(false);
      alert(e.message);
    }
  };

  return (
    <>
      <div className="registration-page form-layouts">
        {loading && (
          <div className="loader">
            <ClipLoader
              color={"#ffffff"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <Container className="mb-5">
          <Row>
            <Col md={12}>
              <div className="forms">
                <h1 className="py-5 mt-0 mt-md-5 mb-3">Welcome to GameUp</h1>
                <Form className="registration-form">
                  <Row className="form-inputs mx-auto py-5">
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 px-md-1 px-3 pt-2"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          onChange={onChange}
                          data-test="name-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 px-md-1 px-3 pt-2"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="username"
                          type="email"
                          placeholder=""
                          onChange={onChange}
                          data-test="email-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 px-md-1 px-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder=""
                          onChange={onChange}
                          data-test="password-input"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-3 px-md-1 px-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                          name="confirmPassword"
                          type="password"
                          placeholder=""
                          onChange={onChange}
                          data-test="confirm-password-input"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Button
                        variant="primary"
                        className="form-btn mt-5 mb-4"
                        onClick={onClick}
                        data-test="signup-button"
                      >
                        Let’s Go
                      </Button>
                    </Col>
                    <Col
                      md={12}
                      className="d-flex justify-content-center align-items-center text-uppercase fw-semibold login-link"
                    >
                      <span>
                        Already have an account?
                        <Link to="/login" className="ms-1 text-decoration-none">
                          LOGIN
                        </Link>
                      </span>
                    </Col>

                    {/*<Col md={12}>
                      <div className="text-center">
                        <img
                          style={{ borderRadius: 7, cursor: "pointer" }}
                          height={120}
                          width={350}
                          src={fbImg}
                          alt={"loginwithfb"}
                          onClick={() =>
                            Auth.federatedSignIn({
                              provider:
                                CognitoHostedUIIdentityProvider.Facebook,
                            })
                          }
                        />
                      </div>
                      <div className="text-center">
                        <img
                          style={{ borderRadius: 7, cursor: "pointer" }}
                          height={62}
                          width={310}
                          src={googleImg}
                          alt={"loginwithfb"}
                          onClick={() =>
                            Auth.federatedSignIn({
                              provider: CognitoHostedUIIdentityProvider.Google,
                            })
                          }
                        />
                      </div>
                      <Button variant="primary" className="form-btn mt-5 mb-4">
                        Signup with Facebook
                      </Button>
                      <Button variant="primary" className="form-btn">
                        Signup with Google
                      </Button>
                    </Col>*/}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>

        <Footer color={"rgb(255 255 255 / 80%)"} />
      </div>
    </>
  );
}
