import Modal from "react-bootstrap/Modal";

import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Team1 from "../assets/images/modal/team-modal-img.png";
import Team2 from "../assets/images/modal/team1.png";
import Team3 from "../assets/images/modal/team2.png";
import Team4 from "../assets/images/modal/team3.png";
import Team5 from "../assets/images/modal/team4.png";
import Team6 from "../assets/images/modal/team5.png";
import Team7 from "../assets/images/modal/team6.png";

import girls1 from "../assets/images/travel-teams/Ellipse-0.png";
import girls2 from "../assets/images/travel-teams/Ellipse-4.png";
import girls3 from "../assets/images/travel-teams/Ellipse-5.png";

import boys1 from "../assets/images/travel-teams/Ellipse-1.png";
import boys2 from "../assets/images/travel-teams/Ellipse-2.png";
import boys3 from "../assets/images/travel-teams/Ellipse-3.png";
import boys4 from "../assets/images/travel-teams/Ellipse-6.png";
import boys5 from "../assets/images/travel-teams/Ellipse-7.png";
import boys6 from "../assets/images/travel-teams/Ellipse-8.png";
import boys7 from "../assets/images/travel-teams/Ellipse-9.png";

import both1 from "../assets/images/travel-teams/player1.png";
import both2 from "../assets/images/travel-teams/player2.png";

import PremiumFeatureSvg from "../assets/images/icons/premium-feature.svg";
import TrainerImg from "../assets/images/modal/trainer-img.png";

const teamNameToImage = (name, gender) => {
  try {
    if (gender === "boys") {
      if (name.length % 7 == 0) {
        return boys1;
      } else if (name.length % 7 == 1) {
        return boys2;
      } else if (name.length % 7 == 2) {
        return boys3;
      } else if (name.length % 7 == 4) {
        return boys4;
      } else if (name.length % 7 == 5) {
        return boys5;
      } else if (name.length % 7 == 6) {
        return boys6;
      } else {
        return boys7;
      }
    } else if (gender === "girls") {
      if (name.length % 2 == 0) {
        return girls1;
      } else if (name.length % 2 == 1) {
        return girls2;
      } else {
        return girls3;
      }
    } else if (gender === "both") {
      if (name.length % 1 == 0) {
        return both1;
      } else {
        return both2;
      }
    } else {
      if (name.length % 7 == 0) {
        return Team1;
      } else if (name.length % 7 == 1) {
        return Team2;
      } else if (name.length % 7 == 2) {
        return Team3;
      } else if (name.length % 7 == 4) {
        return Team4;
      } else if (name.length % 7 == 5) {
        return Team5;
      } else if (name.length % 7 == 6) {
        return Team6;
      } else {
        return Team7;
      }
    }
  } catch {
    return Team3;
  }
};

function capitalizeFirstLetter(string) {
  if (string === undefined) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function TravelTeamsModal(props) {
  return (
    <Modal
      show={props?.modalShow}
      onHide={props?.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="travel-team-modal"
    >
      <Modal.Header closeButton>
          <img
            src={require("../assets/images/modal/team6.png")}
            alt="TeamModalImg"
            className="d-block w-100 team-img"
            style={{width: '100%',
            height: 180,
            objectFit: 'cover',
            objectPosition: 'center'}}
          />
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={()=>props.setModalHandle(false)}
            aria-label="Close"
            style={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
              backgroundColor: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '2rem',
              height: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
      </Modal.Header>
      <Modal.Body style={{
          height: 550,
          padding: 0
      }}>
        <Row style={{padding:0}}>
          <Col xs={5} className="pt-4" style={{width:'90%'}}>
            <h2
              style={{
                fontSize: 30,
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              {props?.item?.name}
            </h2>
          </Col>
        </Row>
        <Row style={{padding:0}}>
          {props?.item?.city && props?.item?.state && (
            <Col xs={7} className="pt-4">
                <p className="mb-0" style={{
                  fontSize: 14,
                  fontWeight: 600,
                  textAlign: 'left',
                  color: "#4B4E55",
                  letterSpacing: "0.045em"
                }}>{`${
                  props?.item?.city.toUpperCase() || ""
                }, ${props?.item?.state}`}</p>
            </Col>
            )}
        </Row>
        <Row>
          {props?.minimumAge !== null && props?.maximumAge !== null && (
            <Col xs={5} className="pt-4">
              <h6 className="mb-0">Age Group:</h6>
            </Col>
          )}
          {props?.minimumAge !== null && props?.maximumAge !== null && (
            <Col xs={7} className="pt-4">
              <p className="mb-0">
                {props?.minimumAge}-{props?.maximumAge}
              </p>
            </Col>
          )}
          {props?.item?.website !== null && props?.item?.website !== null && (
            <Col xs={5} className="pt-4">
              <h6 className="mb-0">Website:</h6>
            </Col>
          )}
          {props?.item?.website !== null && props?.item?.website !== null && (
            <Col xs={5} className="pt-4">
              <p className="mb-0" style={{ color: "#F7941D" }}>
                <a target="_blank" href={props?.item?.website}>
                  Team Website
                </a>
              </p>
            </Col>
          )}
          {props?.coaches && (
            <Col xs={5} className="pt-4">
              {props?.cardType === "team" && <h6 className="mb-0">Coaches:</h6>}
            </Col>
          )}
          {props?.coaches && (
            <Col xs={7} className="pt-4">
              {props?.cardType === "team" && (
                <p className="mb-0">
                  {props?.coaches && props?.coaches.map((coach) => coach.name)}
                </p>
              )}
            </Col>
          )}
          {props?.level && props.level != "Unknown" && (
            <Col xs={5} className="pt-4">
              <h6>Level:</h6>
            </Col>
          )}
          {props?.level && props.level != "Unknown" && (
            <Col xs={7} className="pt-4">
              <p>{props?.level}</p>
            </Col>
          )}
          {props?.website && (
            <Col xs={5} className="pt-4">
              <h6>Website:</h6>
            </Col>
          )}
          {props?.website && (
            <Col xs={7} className="pt-4">
              <p>{props?.website}</p>
            </Col>
          )}
          {props?.type && (
            <Col xs={5} className="pt-4">
              <h6>Type:</h6>
            </Col>
          )}
          {props?.type && (
            <Col xs={7} className="pt-4">
              <p>{props?.type}</p>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} className="pt-4">
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pt-4">
            <div 
              className="bg-dark"
              style={{
                height:160, 
                alignItems:"center", 
                justifyContent:"center",
                display:"flex",
                borderRadius: 10,
                flexDirection:"column",
              }}>
                <h1 style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: '27px',
                  textAlign: 'center',
                  letterSpacing: '0.045em',
                  textTransform: 'uppercase',
                  color: '#FFFFFF',
                  marginBottom: 10
                }}>
                Would you like to speak to a{" "}
                <span style={{
                  color: "#F7941D"
                }}>
                  basketball concierge
                </span>
                ?
              </h1>
              <p className="mb-0">
                <Link
                  className="login-btn"
                  to='/basketball-concierge'
                  style={{
                    textDecoration: "none",
                    fontFamily:"Poppins",
                    fontWeight: 600,
                    fontSize: 13,
                    padding:10,
                    width: 220,
                    alignContent:"center",
                    justifyContent:"center",
                    alignItems:"center",
                    display:"flex",
                    textTransform: "uppercase",
                    letterSpacing: "0.045em"
                  }}
                >
                  Speak to Concierge
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
