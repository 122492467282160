import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import TeamNotPresentModal from "../components/teamNotPresentModal";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";
import GameUpStreams from "../components/GameupStreams";
import { tabTitle } from "../components/tabsTitle";

// const cityOptions = [
//   "Bronx",
//   "Brooklyn",
//   "Manhattan",
//   "Yonkers",
//   "Queens",
//   "Laurelton",
//   "The Bronx",
//   "Westchester",
//   "New Rochelle",
//   "Ardsley",
//   "Harrison",
//   "Albany",
//   "West Hempstead",
//   "Far Rockaway",
//   "Garden City",
//   "Fairfield",
//   "Greenwich",
//   "Edison",
//   "Long Island",
//   "Paterson",
//   "Buffalo",
//   "Rye",
//   "Basking Ridge",
//   "Union",
//   "Neptune",
//   "West Deptford",
//   "Camden",
//   "Scotch Plains",
//   "New Jersey",
//   "Armonk",
//   "Toms River",
//   "Neptune City",
//   "Pitman",
// ];

const cityOptions = [
  "Manhattan",
  "Brooklyn",
  "Queens",
  "Bronx",
  "Staten Island",
];
const libraries = ["places"];

export default function SearchMain() {
  tabTitle("Search");

  const [age, setAge] = useState(null);
  const [gender, setGender] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [cityError, setCityError] = useState("");
  const [aauTeams, setAauTeams] = useState([]);
  const [teamNotPresentModal, setTeamNotPresentModal] = useState(false);
  const [itemToSearch, setItemToSearch] = useState("team");
  const navigate = useNavigate();
  const cityInputRef = useRef(null);
  const footerColor = "rgb(255 255 255 / 80%)";

  const handleAgeChange = (e) => {
    setAge(e.target.value);
    if (e.target.value === "") {
      setAgeError("Age is required");
    } else if (e.target.value <= 0) {
      setAgeError("Age must be positive number");
    } else {
      setAgeError("");
    }
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    if (e.target.value === "") {
      setGenderError("Gender is required");
    } else {
      setGenderError("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    if (e.target.value === "") {
      setCityError("City is required");
    } else {
      setCityError("");
    }
  };

  const validateForm = () => {
    let valid = true;
    if (age === "" || Number(age) <= 0) {
      setAgeError("Please enter a valid age.");
      valid = false;
    } else {
      setAgeError("");
    }

    if (gender === "") {
      setGenderError("Please select a gender.");
      valid = false;
    } else {
      setGenderError("");
    }

    if (city === "" || !city) {
      setCityError("Please enter a valid city.");
      valid = false;
    } else {
      setCityError("");
    }

    return valid;
  };


  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      console.log("handling submit...");
      e.preventDefault();

      if (validateForm()) {
        // const input = {"age": parseInt(age), "city": city.slice(0, 2), "gender": gender.toLowerCase()}
        const filters = {
          gender:
            gender === "both" ? "" : gender === "boys" ? "male" : "female",
          age,
          // city,
        };

        switch (itemToSearch) {
          case "team": {
            const searchString = `A ${gender} team from ${city} for ${age} year olds`;

            const fetchTeams = async (searchString) => {
              // Ping http://ec2-3-87-225-2.compute-1.amazonaws.com:5000/search?query=
              const response = await fetch(
                `https://d31fjbwxgm7wix.cloudfront.net/search?query=${searchString}`
              );
              const data = await response.json();
              return data;
            };
            const aauTeams = await fetchTeams(searchString);
            console.log(aauTeams);
            setAauTeams(aauTeams);

            navigate("/results", {
              state: { aauTeams: aauTeams, filters },
            });
            break;
          }
          case "trainer": {
            const fetchTrainers = async () => {
              const response = await fetch(
                "https://nmtqx9534b.execute-api.us-east-1.amazonaws.com/prod/trainers"
              );

              const { teams: data } = await response.json();

              return data;
            };

            const trainers = await fetchTrainers();

            navigate("/results-trainers", {
              state: { trainers, filters },
            });
            break;
          }
          default:
            break;
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      alert("Failed to complete the search");
      setLoading(false);
    }
  };

  return (
    <div id="wrapper">
      <div className="search-main mn">
        <Container>
          <Row>
            <Col className="search-cnt main">
              <h2>ready to get your baller matched?</h2>
              <p>
                “As a veteran basketball mom, I know first-hand how difficult it
                is to navigate the youth basketball landscape. I created GameUp
                to help you take the guess work out of finding the best program
                for your child. Make GameUp your youth basketball destination!”
              </p>
              <span>
                Crystal McCrary <b> & </b> <br /> <small>cole anthony</small>
              </span>
            </Col>
          </Row>
        </Container>

        <Container>
          <div className="fnd-tm">
            <Form onSubmit={handleSubmit}>
              <div className="fnd-tm-hd">
                <span>find</span>
                <Form.Select
                  value={itemToSearch}
                  onChange={(e) => setItemToSearch(e.target.value)}
                >
                  <option value="team">Team</option>
                  <option value="trainer">Trainer</option>
                  {/* <option value="1">Team2</option>
                  <option value="2">Team3</option> */}
                </Form.Select>
              </div>

              <div className="fn-form">
                <Row className="fn-list">
                  <Col md={4} className="form-grp">
                    <Form.Group>
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Age"
                        onChange={handleAgeChange}
                        data-test="age-input"
                      />
                      {ageError && (
                        <div className="text-danger">{ageError}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} className="form-grp">
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="text-capitalize"
                        onChange={handleGenderChange}
                        data-test="gender-select"
                      >
                        <option value="">select</option>
                        <option value="boys">Boys</option>
                        <option value="girls">Girls</option>
                        <option value="both">Both</option>
                      </Form.Select>
                      {genderError && (
                        <div className="text-danger">{genderError}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} className="form-grp">
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <input
                        onChange={handleCityChange}
                        placeholder="City"
                        data-test="city-input"
                      />
                      {cityError && (
                        <div className="text-danger">{cityError}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <TeamNotPresentModal
                  modalShow={teamNotPresentModal}
                  onHide={() => setTeamNotPresentModal(!teamNotPresentModal)}
                />
                {/*  */}
                {loading ? (
                  <div className="loader">
                    <ClipLoader
                      color={"#ffffff"}
                      loading={true}
                      size={150}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <>
                    <Col md={12} className="fn-btn">
                      <Button
                        variant="primary"
                        type="submit"
                        className="joingame-btn"
                        data-test="submit-button"
                      >
                        start search
                      </Button>
                    </Col>
                    <Col md={12} className="fn-btn lg">
                      <Button variant="primary" type="submit">
                        let's go
                      </Button>
                    </Col>
                  </>
                )}
              </div>
            </Form>
          </div>
        </Container>

        <GameUpStreams />

        <ChatbotFloatingButton />
        <Footer color={footerColor} />
      </div>
    </div>
  );
}
