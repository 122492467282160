import React, { useState, useEffect, useRef } from "react";
import UserIcon from "../assets/images/icons/user-icon.svg";
import LogoIcon from "../assets/images/logo-icon.svg";
import PaperPlaneRightIcon from "../assets/images/icons/paper-plane-right-icon.svg";
import CrossIcon from "../assets/images/icons/cross-icon.svg";
import ArrowOnSquareStackIcon from "../assets/images/icons/arrow-on-square-stack-icon.svg";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Container, Row, Col, Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";


export default function Chatpopup(props) {
  const [messages, setMessages] = useState([
    {
      who: "bot",
      message: "Hi, I'm Terry! Welcome to GameUp!",
    },
  ]);
  const footerColor = "#fff";
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [messageEnded, setMessageEnded] = useState(false);
  const [combinedMessage, setCombinedMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [isThinking, setIsThinking] = useState(false);

  useEffect(() => {
    handleScrollChatbotToBottom();
  }, [messages]);

  const handleEmptyMessage = () => {
    setMessageEnded((prevState) => !prevState);
  };
  
  const handleTimedOutRequest = (message) => {
    if (message.includes("request timed out")) return true;
    return false;
  };
  
  const appendMessage = (message) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastIndex = updatedMessages.length - 1;
      if (lastIndex >= 0) {
        updatedMessages[lastIndex] = {
          ...updatedMessages[lastIndex],
          message: updatedMessages[lastIndex].message + message,
        };
      } else {
        updatedMessages.push({ who: "bot", message: message });
      }
      return updatedMessages;
    });
  };
  
  const startNewMessage = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { who: "bot", message: message },
    ]);
    setMessageEnded(false);
  };

  const conversationToMessages = (conversation) => {
    let parsedData = conversation.map(item => {
        return {
            who: item.type === 'human' ? 'user' : 'bot',
            message: item.data.content
        }
    })
    return parsedData;
  }

  useEffect(() => {
    const fetchMessages = async () => {
      // Check if the conversation endpoint has any messages to load. If so, load them.
      const user = await Auth.currentCredentials().catch((err) =>
          console.log(err)
        );
      const sessionId = user.identityId;
      const conversation_endpoint = `https://d6odwpsicl636h6t6nexto5rjq0saybv.lambda-url.us-east-1.on.aws/${sessionId}`
        try {
          const response = await fetch(conversation_endpoint);
          const data = await response.json();
          console.log(data);
          if (data.messages) {
            const messageHistory = conversationToMessages(data.messages.History);
            setMessages(messageHistory);
          }
        } catch (err) {
          console.log(err);
        }
    };
    fetchMessages();
  },[]);
  
  useEffect(() => {
    const socket = new WebSocket("wss://4m4v4147zk.execute-api.us-east-1.amazonaws.com/production");
    setSocket(socket);
    socket.addEventListener("message", (event) => {
      const lastMessage = event;
      if (lastMessage !== null) {
        
        var messageData = JSON.parse(lastMessage.data)["message"];
        if (messageData === "<START>") {
          handleEmptyMessage();
          startNewMessage("");
          return;
        }
    
        if (handleTimedOutRequest(messageData)) return;
    
        setIsLoading(false);

        
        if (!messageEnded) {
          appendMessage(messageData);
        } else {
          startNewMessage(messageData);
        }
      }
    });
  }, []);
  
  const handleSendMessage = async () => {
    if (!userInput.trim()) return;
    setCombinedMessage("");

    const newMessage = { who: "user", message: userInput };
    setMessages([...messages, newMessage]);
    setUserInput("");

    setIsLoading(true);

    try {
      const user = await Auth.currentCredentials().catch((err) =>
        console.log(err)
      );
      console.log(user);
      const payload = {
        messageBody: userInput,
        phone_number: user.identityId,
      };
      setMessageEnded(true);
      socket.send(JSON.stringify(payload));
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleScrollChatbotToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="chat-popup">
      <Container>
        <Row>
          <Col className="chat-hd">
            <p>chat with GameUp</p>
            <div className="chat-icons">
              <Link to="/chatbot">
                <img
                  className="archv-icon"
                  src={ArrowOnSquareStackIcon}
                  alt="image"
                  style={{ cursor: "pointer" }}
                />
              </Link>
              <img
                className="cncl-icon-c"
                src={CrossIcon}
                alt="image"
                onClick={props.closeChat}
                style={{ cursor: "pointer" }}
              />
            </div>
          </Col>
        </Row>

        <div className="cht-main-div" ref={chatContainerRef}>
          <div className="cht-main">
            {messages.map((message, index) => (
              <Row key={index}>
                <Col
                  md={12}
                  className={`chat-item ${
                    message.who === "user" ? "chat-user" : "chat-bot"
                  }`}
                >
                  <div className="chat-dp">
                    <img src={message.who === "user" ? UserIcon : LogoIcon} />
                  </div>
                  <div className="chat-cnt">
                    {message.who === "bot" ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: message.message,
                        }}
                      />
                    ) : (
                      <p>{message.message}</p>
                    )}
                  </div>
                </Col>
              </Row>
            ))}

            <ClipLoader
              color={"#ff8e15"}
              loading={isLoading}
              size={32}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>

        <div className="enter-txt-c">
          <Form>
            <Row>
              <Col className="txt-box-c">
                <Form.Control
                  as="textarea"
                  placeholder="Your message..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <div className="enter-btn-c" onClick={handleSendMessage}>
                  <img src={PaperPlaneRightIcon} />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
}
