import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
export default function Footer(props) {
  return (
    <div className="footer-outer py-4">
      <Container>
        <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
          <p className="m-0" style={{ color: props.color }}>
            © 2023 GameUp. All rights reserved.
          </p>
          <span className="pt-3 pt-md-0">
            <a
              href="https://amplify-gameupdev-staging-212044-deployment.s3.amazonaws.com/Terms%2Bof%2BService%2B%26%2BPrivacy%2BPolicy-Game-Up.pdf"
              className="ms-2"
              target="_blank"
              style={{ color: props.color }}
            >
              Terms & Conditions
            </a>
            <a
              href="https://amplify-gameupdev-staging-212044-deployment.s3.amazonaws.com/Terms%2Bof%2BService%2B%26%2BPrivacy%2BPolicy-Game-Up.pdf"
              className="ms-2"
              target="_blank"
              style={{ color: props.color }}
            >
              Privacy Policy
            </a>
          </span>
        </div>
      </Container>
    </div>
  );
}
