import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "react-bootstrap/Card";
import BasketBallSliderImage1 from "../assets/images/basketball-slider/basketball-slider-1.png";
import BasketBallSliderImage2 from "../assets/images/basketball-slider/basketball-slider-2.png";
import BasketBallSliderImage3 from "../assets/images/basketball-slider/basketball-slider-3.png";
import BasketBallSliderImage4 from "../assets/images/basketball-slider/basketball-slider-4.png";

export default function BasketballSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "50px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} className="mb-4">
        <div className="slider-cards">
          <Card
            style={{
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              minHeight: "0px",
            }}
          >
            <img src={BasketBallSliderImage1} alt="card_img" />
          </Card>
        </div>
        <div className="slider-cards">
          <Card
            style={{
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              minHeight: "0px",
            }}
          >
            <img src={BasketBallSliderImage2} alt="players_img" />
          </Card>
        </div>
        <div className="slider-cards">
          <Card
            style={{
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              minHeight: "0px",
            }}
          >
            <img src={BasketBallSliderImage3} alt="card_img" />
          </Card>
        </div>
        <div className="slider-cards">
          <Card
            style={{
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              minHeight: "0px",
            }}
          >
            <img src={BasketBallSliderImage4} alt="card_img" />
          </Card>
        </div>
      </Slider>
    </>
  );
}
