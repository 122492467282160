import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation, Storage, Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";

import playerOne from "../assets/images/travel-teams/player1.png";
import userDefaultProfile from "../assets/images/backgrounds/userDefaultProfile.jpg";
import playerTwo from "../assets/images/travel-teams/player2.png";
import playerThree from "../assets/images/travel-teams/player3.png";
import Cards from "../components/cards";
import RecommendationIcon from "../assets/images/icons/recommendation-icon.svg";
import LocationIcon from "../assets/images/icons/location-icon.svg";
import ProfileUploadIcon from "../assets/images/profile.png";
import MakeRecommendationModal from "../components/makeRecommendationModal";
import { tabTitle } from "../components/tabsTitle";
import gameUpLogo from "../assets/images/Profile Placeholder.png";
import ResultsSlider from "../components/resultsSlider";
import GameupStreams from "../components/GameupStreams";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";

import {
  listAAUTeams,
  listPreschoolPrograms,
  listDevelopmentPrograms,
  listCamps,
  listBasketballSchools,
  listRehabs,
  listLeagues,
  listTournaments,
} from "../graphql/queries";

const levelToText = (level) => {
  if (level < 10) {
    return "Never Touched a Ball";
  } else if (level < 20) {
    return "Beginner";
  } else if (level < 30) {
    return "Intermediate";
  } else if (level < 40) {
    return "Advanced";
  } else if (level < 50) {
    return "Elite";
  } else if (level < 60) {
    return "All-Star";
  } else {
    return "Pro-Bound";
  }
};

Amplify.configure(awsconfig);

export default function QueryForm() {
  tabTitle("User Profile");

  const [modalRecommendation, setModalRecommendation] = useState(false);
  const [user, setUser] = useState();
  const [aauTeams, setAauTeams] = useState([]);
  const [preschoolProgram, setPreschoolProgram] = useState([]);
  const [developmentalPrograms, setDevelopmentalPrograms] = useState([]);
  const [camps, setCamps] = useState([]);
  const [basketballSchools, setBasketballSchools] = useState([]);
  const [rehab, setRehab] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userProfile, setUserProfile] = useState(userDefaultProfile);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    userInfo();
    fetchAauTeams();
    fetchPreschoolProgram();
    fetchDevelopmentalPrograms();
    fetchCamps();
    fetchBasketballSchools();
    fetchRehab();
    fetchLeagues();
    fetchTournaments();
  }, []);

  const userInfo = async () => {
    Auth.currentAuthenticatedUser()
      .then(async (data) => {
        const imageAccessURL = await Storage.get(data?.attributes?.picture);
        setUserProfile(imageAccessURL);
        setUser(data);
      })
      .catch(() => console.log("Not signed in"));
  };

  async function fetchAauTeams() {
    try {
      const aauTeamData = await API.graphql(
        graphqlOperation(listAAUTeams, { limit: 5 })
      );
      // console.log(aauTeamData);
      const aauTeams = aauTeamData.data.listAAUTeams.items;
      setAauTeams(aauTeams);
    } catch (err) {
      console.log("error fetching listAAUTeams: ", err);
    }
  }

  async function fetchPreschoolProgram() {
    try {
      const listPreschoolProgramsData = await API.graphql(
        graphqlOperation(listPreschoolPrograms)
      );
      const aauTeams =
        listPreschoolProgramsData.data.listPreschoolPrograms.items;
      setPreschoolProgram(aauTeams);
    } catch (err) {
      console.log("error fetching listPreschoolPrograms: ", err);
    }
  }

  async function fetchDevelopmentalPrograms() {
    try {
      const aauTeamData = await API.graphql(
        graphqlOperation(listDevelopmentPrograms)
      );
      // console.log(aauTeamData);
      const aauTeams = aauTeamData.data.listDevelopmentPrograms.items;
      setDevelopmentalPrograms(aauTeams);
    } catch (err) {
      console.log("error fetching listDevelopmentPrograms: ", err);
    }
  }

  async function fetchCamps() {
    try {
      const aauTeamData = await API.graphql(graphqlOperation(listCamps));
      // console.log(aauTeamData);
      const aauTeams = aauTeamData.data.listCamps.items;
      setCamps(aauTeams);
    } catch (err) {
      console.log("error fetching listCamps: ", err);
    }
  }

  async function fetchBasketballSchools() {
    try {
      const aauTeamData = await API.graphql(
        graphqlOperation(listBasketballSchools)
      );
      // console.log(aauTeamData);
      const aauTeams = aauTeamData.data.listBasketballSchools.items;
      setBasketballSchools(aauTeams);
    } catch (err) {
      console.log("error fetching listBasketballSchools: ", err);
    }
  }

  async function fetchRehab() {
    try {
      const aauTeamData = await API.graphql(graphqlOperation(listRehabs));
      // console.log(aauTeamData);
      const aauTeams = aauTeamData.data.listRehabs.items;
      setRehab(aauTeams);
    } catch (err) {
      console.log("error fetching listRehabs: ", err);
    }
  }

  async function fetchLeagues() {
    try {
      const aauTeamData = await API.graphql(graphqlOperation(listLeagues));
      // console.log("listLeagues: ", aauTeamData);
      const aauTeams = aauTeamData.data.listLeagues.items;
      setLeagues(aauTeams);
    } catch (err) {
      console.log("error fetching listLeagues: ", err);
    }
  }

  async function fetchTournaments() {
    try {
      const aauTeamData = await API.graphql(graphqlOperation(listTournaments));
      // console.log(aauTeamData);
      const aauTeams = aauTeamData.data.listTournaments.items;
      setTournaments(aauTeams);
    } catch (err) {
      console.log("error fetching listTournaments: ", err);
    }
  }

  const uploadPic = async () => {
    try {
      // console.log(selectedFile?.name.split(".")[1]);
      const user = await Auth.currentAuthenticatedUser();
      const result = await Storage.put(
        `${user?.attributes?.sub}.${selectedFile?.name.split(".")[1]}`,
        selectedFile,
        {
          //   acl: "public-read",
          level: "public",
          contentType: selectedFile.type,
        }
      );
      await Auth.updateUserAttributes(user, {
        picture: result.key,
      });
      const imageAccessURL = await Storage.get(
        `${user?.attributes?.sub}.${selectedFile?.name.split(".")[1]}`
      );
      setUserProfile(imageAccessURL);
    } catch (error) {
      console.log("error", error);
    }
  };

  const mapAauTeams = (aauTeams) => {
    return aauTeams.map((item, idx) => {
      return (
        <Col md={3} className="slider-cards" key={idx}>
          <Cards
            cardType="team"
            cardTitle={item.name}
            cardSubTitle={`${item.city == null ? "New York" : item.city}, ${
              item.state == null ? "NY" : item.state
            }`}
            cardImage={playerOne}
            minimumAge={item.minimumAge}
            maximumAge={item.maximumAge}
            city={item.city == "" || item.city == null ? item.city : "New York"}
            state={item.state}
            coaches={item.coaches.items}
            level={levelToText(item.ranking)}
          />
        </Col>
      );
    });
  };

  return (
    <>
      <div className="form-layouts pt-0 pt-md-5 pb-5 results-page">
        <Container className="py-1">
          <Row className="d-flex flex-column profile-banner-section align-items-center py-5">
            {/*<Col xs={12} md={2} className="me-md-0 me-0">
              <div className="founder-profile">
                <div className="founder-Image">
                  <img
                    style={{ objectFit: "fill", height: "100%", width: "100%" }}
                    src={userProfile}
                  />
                </div>
                <div className="profile-upload-icon">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      handleShow();
                      setSelectedFile(e.target.files[0]);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <img src={ProfileUploadIcon} alt="ProfileUploadIcon" />
                </div>
              </div>
                  </Col>*/}
            {/* <Col xs={12} md={2} className="me-md-0 me-0">
                <img src={gameUpLogo} alt="founderImage" />
            </Col> */}
            <Col
              xs={12}
              md={8}
              className="d-flex flex-column align-items-center text-md-start ms-md-3 ms-0 pt-2 banner-content"
            >
              <h1 className="pb-0 pb-md-3 text-center">
                Welcome back,{" "}
                {user?.attributes?.name
                  ? user?.attributes?.name.split(" ")[0]
                  : user?.attributes?.email}
                !
              </h1>
              <div className="pt-4 location-details position-relative">
                <img
                  src={LocationIcon}
                  alt="LocationIcon"
                  className="position-absolute"
                />
                <p className="d-inline-block pe-3 location-ny">New York, NY</p>
                <p className="d-inline-block text-white location-ny px-0">
                  {" "}
                  |{" "}
                </p>
                <p className="d-inline-block px-3 location-url">
                  {user?.attributes?.email}
                </p>
              </div>
            </Col>
          </Row>
          {/* Modal */}
          <MakeRecommendationModal
            modalShow={modalRecommendation}
            onHide={() => setModalRecommendation(!modalRecommendation)}
          />
          {/*  */}
          <Row className="position-relative">
            {/*<Col>
              <span
                className="make-recommendation d-flex align-items-center justify-content-center py-3"
                onClick={() => setModalRecommendation(!modalRecommendation)}
              >
                <img src={RecommendationIcon} alt="RecommendationIcon" />
                <p className="m-0">Make a recommendation</p>
              </span>
            </Col>*/}
          </Row>
        </Container>
      </div>

      <div className="mt-5">
        <GameupStreams hideDescription />
      </div>
      <div className="py-5 my-5 profile-cards d-lg-block d-none">
        <Container>
          <Row>
            <Col
              md={12}
              className="results-page-description position-relative heading-with-icons"
            >
              <img src={RecommendationIcon} alt="RecommendationIcon" />
              <h2 className="text-center text-lg-start">recommendations</h2>
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-start pt-5">AAU Teams</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={aauTeams} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">
                Pre-School Programs
              </h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={preschoolProgram} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">
                Developmental programs
              </h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider
                cardsData={developmentalPrograms}
                cardType="team"
              />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Summer camps</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={camps} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">
                Basketball Schools
              </h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={basketballSchools} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Rehab</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={rehab} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Leagues</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={leagues} cardType="team" />
            </Col>
            {/*<Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Tournaments</h4>
            </Col>
            {tournaments.map((item, idx) => {
              return (
                <Col md={3} className="slider-cards" key={idx}>
                  <Cards
                    cardType="team"
                    id={item?.id}
                    website={item?.website}
                    cardTitle={item?.name}
                    cardSubTitle={`${item?.city || ""}, ${item?.state || ""}`}
                    cardImage={playerOne}
                    minimumAge={item?.minimumAge}
                    maximumAge={item?.maximumAge}
                    city={item?.city}
                    state={item?.state}
                    coaches={item?.coaches?.items}
                    level={levelToText(item?.ranking)}
                  />
                </Col>
              );
            })}*/}
          </Row>
        </Container>
      </div>

      <div className="py-5 mb-5 mt-0 profile-cards d-lg-none d-block profile-mobile-slider">
        <Container>
          <Row>
            <Col
              md={12}
              className="results-page-description position-relative heading-with-icons"
            >
              <img
                className="pb-3 pb-lg-0"
                src={RecommendationIcon}
                alt="RecommendationIcon"
              />
              <h2 className="text-center text-lg-start">recommendations</h2>
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">AAU Teams</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={aauTeams} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">
                Pre-School Programs
              </h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={preschoolProgram} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">
                Developmental programs
              </h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider
                cardsData={developmentalPrograms}
                cardType="team"
              />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Summer camps</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={camps} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">
                Basketball Schools
              </h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={basketballSchools} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Rehab</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={rehab} cardType="team" />
            </Col>
            <Col md={12} className="results-page-description position-relative">
              <h4 className="text-center text-lg-start pt-5">Leagues</h4>
            </Col>
            <Col md={12} className="results-slider-section">
              <ResultsSlider cardsData={leagues} cardType="team" />
            </Col>
          </Row>
        </Container>
      </div>
      <ChatbotFloatingButton />

      <Modal
        centered
        show={show}
        onHide={() => {
          setSelectedFile(null);
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile picture upload confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to upload a profile picture?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedFile(null);
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              uploadPic();
              handleClose();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
