import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from 'react-bootstrap/Card';
import cardsDefaultImage from '../assets/images/slider/Vector.svg'
import playersImage1 from '../assets/images/slider/players-cards-1.png'
import playersImage2 from '../assets/images/slider/players-cards-2.png'
import playersImage3 from '../assets/images/slider/players-cards-3.png'
import playersImage4 from '../assets/images/slider/players-cards-4.png'

export default function SlickSlider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                centerMode: true,
                centerPadding: '30px',
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                  centerMode: true,
                  centerPadding: '30px',
                }
              },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '30px',
              }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '30px',
                    slidesToShow: 1
                  }
              }
          ]
    };
    return (
        <>
            <Slider {...settings} className="mb-4">
                <div className="slider-cards">
                    <Card style={{ alignItems: 'center', paddingTop: '32px', backgroundColor: '#F0F2F5', border: 'none' }}>
                        <Card.Title><img src={cardsDefaultImage} alt="card_img" /></Card.Title>
                        <Card.Body>
                            <h6>GameUp has been a game changer for my son’s basketball development.</h6>
                            <p>Joanna Albright</p>
                        </Card.Body>
                    </Card>
                </div>
                {/* <div className="slider-cards">
                    <Card className="cover-img-card" style={{ alignItems: 'center', border: 'none' }}>
                        <img className="w-100" src={playersImage1} alt="players_img" />
                    </Card>
                </div> */}
                <div className="slider-cards">
                    <Card style={{ alignItems: 'center', paddingTop: '32px', backgroundColor: '#F0F2F5', border: 'none' }}>
                        <Card.Title><img src={cardsDefaultImage} alt="card_img" /></Card.Title>
                        <Card.Body>
                            <h6>GameUp has made my life much easier as a parent trying to figure out the best way to navigate this crazy AAU world.</h6>
                            <p>Peter Brown</p>
                        </Card.Body>
                    </Card>
                </div>
                {/* <div className="slider-cards">
                    <Card className="cover-img-card" style={{ alignItems: 'center', border: 'none' }}>
                        <img className="w-100" src={playersImage2} alt="players_img" />
                    </Card>
                </div> */}
                <div className="slider-cards">
                    <Card style={{ alignItems: 'center', paddingTop: '32px', backgroundColor: '#F0F2F5', border: 'none' }}>
                        <Card.Title><img src={cardsDefaultImage} alt="card_img" /></Card.Title>
                        <Card.Body>
                            <h6>GameUp has been one-stop shopping for all of my daughter’s basketball needs.</h6>
                            <p>Jackie Lee</p>
                        </Card.Body>
                    </Card>
                </div>
                {/* <div className="slider-cards">
                    <Card className="cover-img-card" style={{ alignItems: 'center', border: 'none' }}>
                        <img className="w-100" src={playersImage3} alt="players_img" />
                    </Card>
                </div> */}
                <div className="slider-cards">
                    <Card style={{ alignItems: 'center', paddingTop: '32px', backgroundColor: '#F0F2F5', border: 'none' }}>
                        <Card.Title><img src={cardsDefaultImage} alt="card_img" /></Card.Title>
                        <Card.Body>
                            <h6>GameUp has taken the guess work out of the youth basketball ecosystem.</h6>
                            <p>Derrick Johnson</p>
                        </Card.Body>
                    </Card>
                </div>
                {/* <div className="slider-cards">
                    <Card className="cover-img-card" style={{ alignItems: 'center', border: 'none' }}>
                        <img className="w-100" src={playersImage4} alt="players_img" />
                    </Card>
                </div> */}
            </Slider>
        </>
    )

}