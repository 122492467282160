import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../components/footer";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tabTitle } from "../components/tabsTitle";

export default function Confirmation() {
  tabTitle("Confirmation");

  const { state } = useLocation();
  const navigate = useNavigate();
  const { type, name, email } = state ?? {};
  const footerColor = "rgb(255 255 255 / 80%)";

  useEffect(() => {
    if (!type?.trim() || !name?.trim() || !email?.trim()) {
      navigate("/");
    }
  }, []);

  return (
    <div id="wrapper">
      <div className="trnr-snups confirm-page">
        <Container>
          <Row>
            <Col md={12} className="trnr-cnt vh-100">
              <h2>THANK YOU!</h2>
              <div className="d-flex flex-column text-white gap-4">
                <p>Your {type} registration has been confirmed.</p>
                <p>Your {type} information has been sent to the following: </p>

                <ul>
                  <li>
                    <strong className="fw-bold text-capitalize">
                      {type} Name:{" "}
                    </strong>
                    {name}
                  </li>
                  <li>
                    <strong className="fw-bold">Email Address: </strong>
                    <span className="text-decoration-underline">{email}</span>
                  </li>
                </ul>

                <p>
                  To view your {type} profile page, please{" "}
                  <Link
                    to="/profile"
                    className="text-white text-decoration-underline"
                  >
                    click here.
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <ChatbotFloatingButton />
        <Footer color={footerColor} />
      </div>
    </div>
  );
}
