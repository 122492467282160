import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { Link, useParams } from "react-router-dom";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";

import LocationIcon from "../assets/images/icons/Address.svg";
import EmailIcon from "../assets/images/icons/email.svg";
import PhoneIcon from "../assets/images/icons/phone.svg";
import RateIcon from "../assets/images/icons/rate.svg";
import LinkIcon from "../assets/images/icons/link.svg";
import ClockIcon from "../assets/images/icons/Clock.svg";
import AddCoach from "../assets/images/coaches/addcoach.png";
import EditIcon from "../assets/images/icons/editIcon.svg";
import EditIconBlack from "../assets/images/icons/Edit-Icon.svg";
import EditModalImg from "../assets/images/backgrounds/Team-bg.png";

import { tabTitle } from "../components/tabsTitle";
import EditTeamModal from "../components/editTeamModal";
import AddCoachModal from "../components/addCoachModal";

import { getAAUTeam } from "../graphql/queries";
import { updateAAUTeam, deleteCoach } from "../graphql/mutations";

const ProfileImg = ({ avatar }) => {
  const [imgUrl, setImgUrl] = useState(null);
  useEffect(() => {
    if (avatar) {
      Storage.get(avatar).then((dd) => {
        setImgUrl(dd);
      });
    } else {
      setImgUrl(null);
    }
  }, [avatar]);
  return <img src={imgUrl ? imgUrl : AddCoach} alt="" />;
};

export default function Team() {
  tabTitle("Team");
  let { id } = useParams();

  const [modalShow, setModalShow] = useState(false);
  const [modalCoach, setModalCoach] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [aauTeamsBuffer, setAauTeamsBuffer] = useState({});
  const [aauTeams, setAauTeams] = useState({});
  const [selectCoach, setSelectCoach] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
    getTeamData(id);
  }, []);

  const getUser = async () => {
    const user = await Auth.currentAuthenticatedUser().catch(() => {});
    setUser(user);
  };

  async function getTeamData(id) {
    try {
      setLoading(true);
      var aauTeamData;
      const isAuthenticated = await Auth.currentAuthenticatedUser().catch(
        () => {}
      );
      if (isAuthenticated) {
        aauTeamData = await API.graphql(graphqlOperation(getAAUTeam, { id }));
      } else {
        aauTeamData = await API.graphql({
          ...graphqlOperation(getAAUTeam, { id }),
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }
      if (aauTeamData?.data?.getAAUTeam?.banner) {
        await getBannerImage(aauTeamData?.data?.getAAUTeam?.banner);
      }
      const aauTeamsData = {
        id: aauTeamData?.data?.getAAUTeam?.id || id,
        name: aauTeamData?.data?.getAAUTeam?.name || "",
        banner: aauTeamData?.data?.getAAUTeam?.banner || "",
        ranking: aauTeamData?.data?.getAAUTeam?.ranking || 10,
        sponsorship: aauTeamData?.data?.getAAUTeam?.sponsorship || "",
        website: aauTeamData?.data?.getAAUTeam?.website || "",
        minimumAge: aauTeamData?.data?.getAAUTeam?.minimumAge,
        maximumAge: aauTeamData?.data?.getAAUTeam?.maximumAge,
        gender: aauTeamData?.data?.getAAUTeam?.gender || "",
        street: aauTeamData?.data?.getAAUTeam?.street || "",
        city: aauTeamData?.data?.getAAUTeam?.city || "",
        state: aauTeamData?.data?.getAAUTeam?.state || "",
        zipcode: aauTeamData?.data?.getAAUTeam?.zipcode || "",
        coaches: {
          items:
            aauTeamData?.data?.getAAUTeam?.coaches?.items?.length > 0
              ? aauTeamData?.data?.getAAUTeam?.coaches?.items.filter(
                  (i) => i._deleted !== true
                )
              : [],
        },
        facebookPageUrl: aauTeamData?.data?.getAAUTeam?.facebookPageUrl || "",
        instagramPageUrl: aauTeamData?.data?.getAAUTeam?.instagramPageUrl || "",
        schedule: aauTeamData?.data?.getAAUTeam?.schedule || "",
        email: aauTeamData?.data?.getAAUTeam?.email?.toLowerCase() || "",
        phoneNumber: aauTeamData?.data?.getAAUTeam?.phoneNumber || "",
        difficulty: aauTeamData?.data?.getAAUTeam?.difficulty || "",
        teamType: aauTeamData?.data?.getAAUTeam?.teamType || "",
        league: aauTeamData?.data?.getAAUTeam?.league || "",
        tryouts: aauTeamData?.data?.getAAUTeam?.tryouts || "",
        tryoutLocation: aauTeamData?.data?.getAAUTeam?.tryoutLocation || "",
        gymSpace: aauTeamData?.data?.getAAUTeam?.gymSpace || "",
        teamCost: aauTeamData?.data?.getAAUTeam?.teamCost || "",
        about: aauTeamData?.data?.getAAUTeam?.about || "",
        _version: aauTeamData?.data?.getAAUTeam?._version || 3,
      };
      setAauTeamsBuffer(aauTeamsData);
      setAauTeams(aauTeamsData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error fetching getAAUTeam: ", err);
    }
  }

  const levelToText = (level) => {
    if (level < 10) {
      return "Never Touched a Ball";
    } else if (level < 20) {
      return "Beginner";
    } else if (level < 30) {
      return "Intermediate";
    } else if (level < 40) {
      return "Advanced";
    } else if (level < 50) {
      return "Elite";
    } else if (level < 60) {
      return "All-Star";
    } else {
      return "Pro-Bound";
    }
  };

  const textToRanking = (text) => {
    if (text === "Never Touched a Ball") {
      return 10;
    } else if (text === "Beginner") {
      return 20;
    } else if (text === "Intermediate") {
      return 30;
    } else if (text === "Advanced") {
      return 40;
    } else if (text === "Elite") {
      return 50;
    } else if (text === "All-Star") {
      return 60;
    } else {
      return 70;
    }
  };

  const getBannerImage = async (picture) => {
    const imageAccessURL = await Storage.get(picture).catch((err) =>
      console.log(err)
    );
    setBannerImage(imageAccessURL);
  };

  const editTeam = async () => {
    try {
      const updateData = { ...aauTeams };
      delete updateData?.coaches;
      setLoading(true);
      const aauTeamData = await API.graphql({
        query: updateAAUTeam,
        variables: {
          input: updateData,
        },
      });
      if (aauTeamData?.data?.updateAAUTeam) {
        alert("Team update successfully");
        setAauTeamsBuffer(aauTeams);
        setAauTeams(aauTeams);
        setIsEditable(false);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      alert("Getting some error in team update!");
      setIsEditable(false);
      setLoading(false);
      setAauTeams(aauTeamsBuffer);
    }
  };

  const cancelTeam = () => {
    setIsEditable(false);
    setAauTeams(aauTeamsBuffer);
  };

  const deleteCoachData = async (coachId, _version) => {
    try {
      setLoading(true);
      const deleteData = await API.graphql(
        graphqlOperation(deleteCoach, {
          input: { id: coachId, _version },
        })
      );
      setLoading(false);
      getTeamData(id);
    } catch (error) {
      console.log("error", error);
      alert("Getting some error in delete coach!");
      setLoading(false);
    }
  };

  let isRender = false;

  return (
    <>
      {loading && (
        <div className="loader">
          <ClipLoader
            color={"#ffffff"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div
        className="pt-0 pt-md-5 pb-0 pb-md-5 teamBg"
        style={{
          backgroundImage: `url(${bannerImage ? bannerImage : EditModalImg})`,
        }}
      >
        <Container>
          <Row>
            <Col md={12} className="position-relative pt-0 pt-md-3 pb-3">
              <div className="forms pt-0 pt-md-3 pb-3">
                <h1 className="py-5">{aauTeams?.name}</h1>
                <div className="team-edit">
                  <span>
                    {isEditable && (
                      <Button
                        className="team-edit-text"
                        onClick={() => setModalShow(true)}
                      >
                        <img src={EditIcon} alt="" /> edit team name and banner
                      </Button>
                    )}
                    <EditTeamModal
                      team={aauTeams}
                      show={modalShow}
                      update={() => {
                        getTeamData(id);
                        setModalShow(false);
                      }}
                      onHide={() => setModalShow(false)}
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="p-0 m-0">
        <Container className="p-0 team-container">
          <Row md={12} className="p-0 m-0">
            {/* Side Bar  */}
            {!isEditable ? (
              <Col md={4} className="p-0 m-0">
                <div className="left-side team-contact">
                  {/* four spacing */}
                  <div className="main-address contact">
                    {aauTeams?.city && (
                      <div className="adress-container left-container">
                        <span className="icon-width">
                          <img
                            src={LocationIcon}
                            className="location-icon"
                            alt="Location_icon"
                          />
                        </span>
                        <span className="left-text">
                          {aauTeams?.city}
                          {aauTeams?.state ? `, ${aauTeams.state}` : null}
                        </span>
                      </div>
                    )}
                    {aauTeams?.email && (
                      <div className="email-container left-container">
                        <span className="icon-width">
                          <img
                            src={EmailIcon}
                            className="location-icon"
                            alt="Location_icon"
                          />
                        </span>
                        <span>
                          <Link
                            // eslint-disable-next-line no-script-url
                            to="#"
                            onClick={() =>
                              (window.location = `mailto:${aauTeams?.email}`)
                            }
                          >
                            {aauTeams?.email}
                          </Link>
                        </span>
                      </div>
                    )}
                    {aauTeams?.phoneNumber && (
                      <div className="adress-container left-container">
                        <span className="icon-width">
                          <img
                            src={PhoneIcon}
                            className="location-icon"
                            alt="Location_icon"
                          />
                        </span>
                        <span className="left-text">
                          {aauTeams?.phoneNumber}
                        </span>
                      </div>
                    )}
                    <div className="adress-container left-container">
                      {(aauTeams?.facebookPageUrl ||
                        aauTeams?.instagramPageUrl) && (
                        <span className="icon-width">
                          <img
                            src={RateIcon}
                            className="location-icon"
                            alt="Location_icon"
                          />
                        </span>
                      )}
                      <div className="socials">
                        {aauTeams?.facebookPageUrl && (
                          <span className="left-text">
                            <a
                              href={aauTeams?.facebookPageUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Facebook
                            </a>
                          </span>
                        )}
                        {aauTeams?.instagramPageUrl && (
                          <span className="left-text">
                            <a
                              href={aauTeams?.instagramPageUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Instagram
                            </a>
                          </span>
                        )}
                      </div>
                    </div>
                    {aauTeams?.website && (
                      <div className="adress-container left-container">
                        <span className="icon-width">
                          <img
                            src={LinkIcon}
                            className="location-icon"
                            alt="Location_icon"
                          />
                        </span>
                        <span className="left-text">
                          <a
                            href={aauTeams?.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {aauTeams?.website}
                          </a>
                        </span>
                      </div>
                    )}
                    {aauTeams?.schedule && (
                      <div className="adress-container left-container">
                        <span className="icon-width">
                          <img
                            src={ClockIcon}
                            className="location-icon"
                            alt="Location_icon"
                          />
                        </span>
                        <span className="left-text">{aauTeams?.schedule}</span>
                      </div>
                    )}
                  </div>
                  <div className="button-section">
                    {!isEditable &&
                      Object.keys(aauTeams).length > 0 &&
                      user?.username === aauTeams?.email && (
                        <button
                          className="btn edit-profile-btn"
                          onClick={() => setIsEditable(true)}
                        >
                          <img src={EditIconBlack} alt="" /> EDIT PROFILE
                        </button>
                      )}
                  </div>
                </div>
              </Col>
            ) : (
              <Col md={4} className="p-0 m-0">
                <div className="left-side team-contact">
                  {/* four spacing */}
                  <div className="main-address contact">
                    <div className="adress-container left-container">
                      <span className="icon-width">
                        <img
                          src={LocationIcon}
                          className="location-icon"
                          alt="Location_icon"
                        />
                      </span>
                      <span className="text-field">
                        <textarea
                          className="left-text input-style"
                          name="street"
                          id="street"
                          value={aauTeams?.street}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="email-container left-container">
                      <span className="icon-width">
                        <img
                          src={EmailIcon}
                          className="location-icon"
                          alt="Location_icon"
                        />
                      </span>
                      <span className="text-field">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="left-text input-style"
                          value={aauTeams?.email}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="adress-container left-container">
                      <span className="icon-width">
                        <img
                          src={PhoneIcon}
                          className="location-icon"
                          alt="Location_icon"
                        />
                      </span>
                      <span className="text-field">
                        <input
                          name="phoneNumber"
                          id="phoneNumber"
                          type="text"
                          className="left-text input-style"
                          value={aauTeams?.phoneNumber}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="adress-container left-container">
                      <span className="icon-width">
                        <img
                          src={RateIcon}
                          className="location-icon"
                          alt="Location_icon"
                        />
                      </span>
                      <span className="socials text-field">
                        <input
                          name="facebookPageUrl"
                          id="facebookPageUrl"
                          type="text"
                          className="left-text input-style"
                          value={aauTeams?.facebookPageUrl}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="adress-container left-container">
                      <span className="icon-width">
                        {/* <img
                          src={RateIcon}
                          className="location-icon"
                          alt="Location_icon"
                        /> */}
                      </span>
                      <span className="socials text-field">
                        <input
                          name="instagramPageUrl"
                          id="instagramPageUrl"
                          type="text"
                          className="left-text input-style"
                          value={aauTeams?.instagramPageUrl}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="adress-container left-container">
                      <span className="icon-width">
                        <img
                          src={LinkIcon}
                          className="location-icon"
                          alt="Location_icon"
                        />
                      </span>
                      <span className="text-field">
                        <input
                          name="website"
                          id="website"
                          className="left-text input-style"
                          value={aauTeams?.website}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                    <div className="adress-container left-container">
                      <span className="icon-width">
                        <img
                          src={ClockIcon}
                          className="location-icon"
                          alt="Location_icon"
                        />
                      </span>
                      <span className="text-field">
                        <input
                          name="schedule"
                          id="schedule"
                          className="left-text input-style"
                          value={aauTeams?.schedule}
                          onChange={(e) =>
                            setAauTeams({
                              ...aauTeams,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>
                  </div>
                  <div className="button-section">
                    {!isEditable ? (
                      Object.keys(aauTeams).length > 0 && (
                        <button
                          className="btn edit-profile-btn"
                          onClick={() => setIsEditable(true)}
                        >
                          <img src={EditIconBlack} alt="" /> EDIT PROFILE
                        </button>
                      )
                    ) : (
                      <div className="afterEditBtn">
                        <button
                          type="button"
                          className="btn save-btn"
                          onClick={() => editTeam()}
                        >
                          SAVE CHANGES
                        </button>
                        <button
                          type="button"
                          className="btn cancel-btn"
                          onClick={() => cancelTeam()}
                        >
                          CANCEL
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )}

            {/* Main Container  */}
            {!isEditable ? (
              <Col md={8} className="p-0 m-0">
                <div className="main-content-container right-container">
                  <p className="main-para right-para">{aauTeams?.about}</p>
                  <div className="team-info-container">
                    <Container>
                      <Row>
                        <Col md={12}>
                          <Row className="team-stats">
                            {aauTeams?.ranking && (
                              <>
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>Difficulty:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info">
                                    <ul className="diff-list">
                                      {[10, 20, 30, 40, 50, 60, 70].map(
                                        (item) => {
                                          let isShow = isRender
                                            ? false
                                            : textToRanking(aauTeams?.ranking) <
                                              item;
                                          if (isShow) isRender = true;
                                          return (
                                            <li
                                              key={item}
                                              className={`${
                                                isShow && "active"
                                              }`}
                                            >
                                              {isShow && (
                                                <div className="diff-tooltip">
                                                  <span className="elite">
                                                    {aauTeams?.ranking}
                                                  </span>
                                                </div>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </Col>
                              </>
                            )}
                            {aauTeams?.gender && (
                              <div className="inline gender">
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>GENDER:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info">
                                    <span className="info-text">
                                      {aauTeams?.gender}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                            )}
                            <div className="inline age">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>Age range:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <span className="info-text">
                                    {aauTeams?.minimumAge} -
                                    {aauTeams?.maximumAge}
                                  </span>
                                </div>
                              </Col>
                            </div>
                            {aauTeams?.teamType && (
                              <div className="inline league">
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>Team type:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info">
                                    <span className="info-text">
                                      {aauTeams?.teamType}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                            )}
                            {aauTeams?.league && (
                              <div className="inline league">
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>League:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info">
                                    <span className="info-text">
                                      {aauTeams?.league}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                            )}
                            {aauTeams?.tryouts && (
                              <div className="inline try">
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>Tryouts:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info info-gap">
                                    <span className="info-text">
                                      {aauTeams?.tryouts}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                            )}
                            {aauTeams?.gymSpace && (
                              <div className="inline gym">
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>Gym Space:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info">
                                    <span className="info-text">
                                      {aauTeams?.gymSpace}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                            )}
                            {aauTeams?.teamCost && (
                              <div className="inline team">
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>Team cost:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info">
                                    <span className="info-text">
                                      {aauTeams?.teamCost}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                            )}
                            {aauTeams?.coaches?.items?.length > 0 && (
                              <>
                                <Col md={3}>
                                  <div className="team-point">
                                    <span>Coaches:</span>
                                  </div>
                                </Col>
                                <Col md={9}>
                                  <div className="team-point-info coaches-container">
                                    {aauTeams?.coaches?.items?.map(
                                      (item, idx) => (
                                        <div key={idx} className="team-coaches">
                                          <div className="coach-img">
                                            <ProfileImg avatar={item?.avatar} />
                                          </div>
                                          <div className="coach-info">
                                            <div className="coach-name">
                                              <span className="bold">
                                                {item?.name}
                                              </span>
                                            </div>
                                            <div className="coach-address">
                                              <span className="email-text d-block">
                                                Cost of training: $
                                                {item?.costOfTraining}/hr
                                                <Link
                                                  className="coach-id"
                                                  // eslint-disable-next-line no-script-url
                                                  to="#"
                                                  onClick={() =>
                                                    (window.location = `mailto:${item?.email}`)
                                                  }
                                                >
                                                  {item?.email}
                                                </Link>
                                              </span>
                                            </div>
                                            <div className="coach-social">
                                              <span className="orange bold">
                                                {item?.userName}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Col>
                              </>
                            )}
                            <Col md={3}>
                              <div className="team-point"></div>
                            </Col>
                            <Col md={9}></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            ) : (
              <Col md={8} className="p-0 m-0">
                <div className="main-content-container right-container">
                  <p className="main-para right-para">
                    <textarea
                      name="about"
                      id="about"
                      cols="30"
                      rows="10"
                      value={aauTeams?.about}
                    />
                  </p>
                  <div className="team-info-container editable-container">
                    <Container>
                      <Row>
                        <Col md={12}>
                          <Row className="team-stats">
                            <div className="inline res">
                              <Col md={3}>
                                <div className="team-point">
                                  <span className="headWidth">Difficulty:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <select
                                    className="choose-difficulty"
                                    name="ranking"
                                    id="ranking"
                                    value={aauTeams?.ranking}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  >
                                    <option value={"10"}>1 - Elite</option>
                                    <option value={"20"}>2 - Elite</option>
                                    <option value={"30"}>3 - Elite</option>
                                    <option value={"40"}>4 - Elite</option>
                                    <option value={"50"}>5 - Elite</option>
                                    <option value={"60"}>6 - Elite</option>
                                    <option value={"70"}>7 - Elite</option>
                                  </select>
                                </div>
                              </Col>
                            </div>
                            <div className="inline gender">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>GENDER:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <select
                                    className="choose-difficulty "
                                    name="gender"
                                    id="gender"
                                    value={aauTeams?.gender}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="girls">Girls</option>
                                    <option value="boys">Boys</option>
                                  </select>
                                </div>
                              </Col>
                            </div>
                            <div className="inline age">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>Age range:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <input
                                    style={{ marginBottom: 5 }}
                                    placeholder="Minimum age"
                                    className="info-text choose-difficulty"
                                    name="minimumAge"
                                    id="minimumAge"
                                    value={aauTeams?.minimumAge}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                  <input
                                    placeholder="Maximum age"
                                    className="info-text choose-difficulty"
                                    name="maximumAge"
                                    id="maximumAge"
                                    value={aauTeams?.maximumAge}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                            <div className="inline res">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>Team type:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <input
                                    name="teamType"
                                    id="teamType"
                                    className="info-text choose-difficulty"
                                    value={aauTeams?.teamType}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                            <div className="inline league">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>League:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <input
                                    name="league"
                                    id="league"
                                    className="info-text choose-difficulty"
                                    value={aauTeams?.league}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                            <div className="inline try">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>Tryouts:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info info-gap">
                                  <textarea
                                    name="tryouts"
                                    id="tryouts"
                                    className="info-text"
                                    value={aauTeams?.tryouts}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                            <div className="inline gym">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>Gym Space:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <input
                                    className="info-text choose-difficulty"
                                    name="gymSpace"
                                    id="gymSpace"
                                    value={aauTeams?.gymSpace}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                            <div className="inline team">
                              <Col md={3}>
                                <div className="team-point">
                                  <span>Team cost:</span>
                                </div>
                              </Col>
                              <Col md={9}>
                                <div className="team-point-info">
                                  <input
                                    className="info-text choose-difficulty"
                                    name="teamCost"
                                    id="teamCost"
                                    value={aauTeams?.teamCost}
                                    onChange={(e) =>
                                      setAauTeams({
                                        ...aauTeams,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                            <Col md={3}>
                              <div className="team-point">
                                <span>Coaches:</span>
                              </div>
                            </Col>
                            <Col md={9}>
                              <div className="team-point-info coaches-container">
                                {aauTeams?.coaches?.items?.map((item) => (
                                  <div key={item?.id} className="team-coaches">
                                    <div className="coach-img">
                                      <ProfileImg avatar={item?.avatar} />
                                    </div>
                                    <div className="coach-info">
                                      <div className="coach-name">
                                        <span className="bold">
                                          {item?.name}
                                        </span>
                                      </div>
                                      <div className="coach-address">
                                        <span className="email-text d-block">
                                          Cost of training: $
                                          {item?.costOfTraining}/hr
                                          <Link
                                            className="coach-id"
                                            // eslint-disable-next-line no-script-url
                                            to="#"
                                            onClick={() =>
                                              (window.location = `mailto:${item?.email}`)
                                            }
                                          >
                                            {item?.email}
                                          </Link>
                                        </span>
                                      </div>
                                      <div className="coach-social">
                                        <span className="orange bold">
                                          {item?.userName}
                                        </span>
                                      </div>
                                      <div className="coach-btns">
                                        <button
                                          className="coach-edit-btn"
                                          onClick={() => [
                                            setSelectCoach(item),
                                            setModalCoach(true),
                                          ]}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="coach-del-btn"
                                          onClick={() =>
                                            deleteCoachData(
                                              item?.id,
                                              item?._version
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="team-point"></div>
                            </Col>
                            <Col md={9}>
                              <div className="add-coach">
                                <Button
                                  className="team-edit-text add-coach-btn"
                                  onClick={() => setModalCoach(true)}
                                >
                                  <div className="add-coach-icon">
                                    <svg
                                      width="67"
                                      height="67"
                                      viewBox="0 0 67 67"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="33.5"
                                        cy="33.5"
                                        r="33"
                                        fill="#FBFBFB"
                                        stroke="#D9D9D6"
                                      />
                                      <path
                                        opacity="0.5"
                                        d="M46.4532 35.8865H36.1416V46.4534H30.7306V35.8865H20.4189V30.9859H30.7306V20.4191H36.1416V30.9859H46.4532V35.8865Z"
                                        fill="#231F20"
                                      />
                                    </svg>
                                  </div>
                                  <div className="add-coach-text">
                                    <span>Add a coach</span>
                                  </div>
                                </Button>
                                <AddCoachModal
                                  show={modalCoach}
                                  onHide={() => [
                                    setModalCoach(false),
                                    setSelectCoach({}),
                                  ]}
                                  coach={selectCoach}
                                  teamID={id}
                                  addUpdate={() => {
                                    setModalCoach(false);
                                    getTeamData(id);
                                    setSelectCoach({});
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {/* <EditTeamModal /> */}
    </>
  );
}
