import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { ClipLoader } from "react-spinners";
import Geocode from "react-geocode";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import UserWhiteIcon from "../assets/images/icons/user-white-icon.svg";
import Footer from "../components/footer";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";
import { tabTitle } from "../components/tabsTitle";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage("en");

const FORM_INITIAL_VALUES = {
  name: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  phoneNumber: "",
  socialMediaLinks: "",
  website: "",
  hours: "",
  ranking: "",
  about: "",
  gender: "",
  ageRange: "",
  teamCost: "",
  teamType: "",
  tryouts: "",
  gymSpace: "",
  profileImage: "",
  banner: "",
};

const validationSchema = yup.object().shape({
  name: yup.string().required("Team name is required"),
  city: yup.string().required("Type a valid ZIP code to populate city"),
  state: yup.string().required("Type a valid ZIP code to populate state"),
  zipcode: yup
    .string()
    .required("Zipcode is required")
    .matches(/^\d{5}$/, "Zipcode must be 5 digits"),
  email: yup.string().required("Email is required").email("Invalid email"),
  phoneNumber: yup.string().required("Phone number is required"),
  socialMediaLinks: yup.string().required("Social media handles are required"),
  website: yup.string().required("Website is required"),
  hours: yup.string().required("Hours are required"),
  ranking: yup.string().required("Difficulty is required"),
  about: yup.string().required("Description is required"),
  gender: yup.string().required("Gender is required"),
  ageRange: yup.string().required("Age range is required"),
  teamCost: yup.string().required("Team cost is required"),
  teamType: yup.string().required("Team type is required"),
  tryouts: yup.string().required("Tryouts are required"),
  gymSpace: yup.string().required("Gym space is required"),
  profileImage: yup.string().notRequired(),
  banner: yup.string().notRequired(),
});

export default function TrainerSignUp() {
  const footerColor = "rgb(255 255 255 / 80%)";
  tabTitle("New Team");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [bannerURL, setBannerURL] = useState(null);
  const profileImageInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: FORM_INITIAL_VALUES,
    resolver: yupResolver(validationSchema),
  });
  const watchZipcode = watch("zipcode");
  const watchProfileImage = watch("profileImage");
  const watchbanner = watch("banner");
  const watchTeamName = watch("name");
  const watchEmail = watch("email");

  const handleImageFileChange = async (inputRef, fieldName) => {
    if (inputRef.current?.files) {
      const file = inputRef.current.files[0];
      const key = `uploads/${Date.now()}-${file.name}`;

      try {
        await Storage.put(key, file);
        setValue(fieldName, key);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  const handleOpenFileInput = (inputRef) => {
    inputRef.current?.click();
  };

  const handleRemoveImageFile = async (inputRef, fieldName, setUrlState) => {
    if (!getValues(fieldName)) return;

    try {
      await Storage.remove(getValues(fieldName));
      setUrlState("");
      setValue(fieldName, "");

      inputRef.current.value = null;
    } catch (error) {
      console.log("Error removing file: ", error);
    }
  };

  const getImageURL = async (key) => {
    if (!key) return null;
    try {
      const url = await Storage.get(key);
      return url;
    } catch (error) {
      console.log("Error getting profile image URL:", error);
      return null;
    }
  };

  const handleGetAddressFromZipCode = (zipcode) => {
    Geocode.fromAddress(zipcode.concat(" USA")).then((response) => {
      let city, state;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
            case "sublocality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].short_name;
              break;
            default:
              break;
          }
        }
      }
      setValue("city", city);
      setValue("state", state);
      setError("city", {
        message: city ? "" : "Type a valid ZIP code to populate city",
      });
      setError("state", {
        message: state ? "" : "Type a valid ZIP code to populate state",
      });
    });
  };

  useEffect(() => {
    const fetchProfileImageURL = async (fieldValue, setUrlState) => {
      const url = await getImageURL(fieldValue);
      setUrlState(url);
    };

    fetchProfileImageURL(watchProfileImage, setProfileImageURL);
    fetchProfileImageURL(watchbanner, setBannerURL);
  }, [watchProfileImage, watchbanner]);

  useEffect(() => {
    const isValidZipCode = watchZipcode.length === 5 && !isNaN(watchZipcode);
    if (isValidZipCode) {
      handleGetAddressFromZipCode(watchZipcode);
    } else {
      setValue("city", "");
      setValue("state", "");

      if (touchedFields.zipcode) {
        setError("city", { message: "Type a valid ZIP code to populate city" });
        setError("state", {
          message: "Type a valid ZIP code to populate state",
        });
      }
    }
  }, [watchZipcode]);

  const onSubmit = async (values, e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const [minimumAge, maximumAge] = values.ageRange.split("-");
      const input = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...values,
          id: values.name.split(" ").join("-").toLowerCase(),
          miniumAge: Number(minimumAge),
          maximumAge: Number(maximumAge),
        }),
        mode: "cors",
      };

      const apiUrl =
        "https://1h3m5ddhnl.execute-api.us-east-1.amazonaws.com/prod";

      const res = await fetch(`${apiUrl}/register`, input);

      if (!res.ok) {
        throw new Error(res.body);
      }

      setIsSubmitting(false);
      navigate("/confirmation", {
        state: {
          type: "team",
          name: values.name,
          email: values.email,
        },
      });
    } catch (error) {
      console.error("Error registering team:", error);
      setIsSubmitting(false);
      alert("Error registering team");
    }
  };

  return (
    <div id="wrapper">
      <div className="trnr-snup">
        <Container>
          <Row>
            <Col md={12} className="trnr-cnt">
              <h2>Register your team</h2>
              <Form
                id="trainer-signup-form"
                onSubmit={handleSubmit(onSubmit)}
                className="r-form"
              >
                <Row className="r-form-list">
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <div className="d-flex align-items-center">
                      <Col className="f-prfl-lft">
                        <img
                          src={profileImageURL ?? UserWhiteIcon}
                          alt="image"
                        />
                        <input
                          id="profileImage"
                          type="file"
                          ref={profileImageInputRef}
                          onChange={() =>
                            handleImageFileChange(
                              profileImageInputRef,
                              "profileImage"
                            )
                          }
                          name="profileImage"
                          style={{ display: "none" }}
                          data-test="profile-image-input"
                        />
                      </Col>
                      <Col className="f-prfl-rht">
                        <p
                          role="button"
                          className="cursor-pointer"
                          onClick={() =>
                            handleOpenFileInput(profileImageInputRef)
                          }
                          data-test="upload-profile-image-button"
                        >
                          Team profile image
                        </p>
                        <button
                          type="button"
                          data-test="remove-profile-image-button"
                          onClick={() =>
                            handleRemoveImageFile(
                              profileImageInputRef,
                              "profileImage",
                              setProfileImageURL
                            )
                          }
                        >
                          Remove
                        </button>
                      </Col>
                    </div>
                  </Col>
                  <Col md={6} className="d-flex flex-column r-form-item">
                    <div className="d-flex align-items-center">
                      <Col>
                        <div
                          className={`banner-image-container ${
                            bannerURL && "image-uploaded"
                          }`}
                        >
                          {bannerURL ? (
                            <img src={bannerURL} />
                          ) : (
                            <span>UPLOAD IMAGE</span>
                          )}
                        </div>
                        {/* <img
                          src={profileImageURL ?? UserWhiteIcon}
                          alt="image"
                        /> */}
                        <input
                          id="banner"
                          type="file"
                          ref={bannerInputRef}
                          onChange={() =>
                            handleImageFileChange(bannerInputRef, "banner")
                          }
                          name="banner"
                          style={{ display: "none" }}
                          data-test="banner-image-input"
                        />
                      </Col>
                      <Col className="f-prfl-rht">
                        <p
                          role="button"
                          className="cursor-pointer"
                          onClick={() => handleOpenFileInput(bannerInputRef)}
                          data-test="upload-banner-image-button"
                        >
                          Banner image
                        </p>
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveImageFile(
                              bannerInputRef,
                              "banner",
                              setBannerURL
                            )
                          }
                          data-test="remove-banner-image-button"
                        >
                          Remove
                        </button>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row className="r-form-list">
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Team Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Team Name"
                        isInvalid={!!errors.name}
                        {...register("name")}
                        data-test="name-input"
                      />
                    </Form.Group>
                    {errors.name && (
                      <small className="text-danger align-text-start">
                        {errors.name.message}
                      </small>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Team ZIP Code</Form.Label>
                      <Form.Control
                        placeholder="ex: 12345"
                        isInvalid={!!errors.zipcode}
                        {...register("zipcode")}
                        maxLength={5}
                        data-test="zipcode-input"
                      />
                    </Form.Group>
                    {errors.zipcode && (
                      <small className="text-danger align-text-start">
                        {errors.zipcode.message}
                      </small>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Team City</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.city?.message}
                        {...register("city")}
                        disabled
                        data-test="city-input"
                      />
                    </Form.Group>
                    {errors.city && (
                      <small className="text-danger align-text-start">
                        {errors.city.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Team State</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.state?.message}
                        {...register("state")}
                        disabled
                        data-test="state-input"
                      />
                    </Form.Group>
                    {errors.state && (
                      <small className="text-danger align-text-start">
                        {errors.state.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        placeholder="example@email.com"
                        isInvalid={!!errors.email}
                        {...register("email")}
                        data-test="email-input"
                      />
                    </Form.Group>
                    {errors.email && (
                      <small className="text-danger align-text-start">
                        {errors.email.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        placeholder="(123) 456-7890"
                        isInvalid={!!errors.phoneNumber}
                        {...register("phoneNumber")}
                        data-test="phone-number-input"
                      />
                    </Form.Group>
                    {errors.phoneNumber && (
                      <small className="text-danger align-text-start">
                        {errors.phoneNumber.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>
                        Social Media Links (Separate with commas)
                      </Form.Label>
                      <Form.Control
                        placeholder="facebook.com/url, instagram.com/url"
                        isInvalid={!!errors.socialMediaLinks}
                        {...register("socialMediaLinks")}
                        data-test="social-media-links-input"
                      />
                    </Form.Group>
                    {errors.socialMediaLinks && (
                      <small className="text-danger align-text-start">
                        {errors.socialMediaLinks.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Website Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="https://"
                        isInvalid={!!errors.website}
                        {...register("website")}
                        data-test="website-input"
                      />
                    </Form.Group>
                    {errors.website && (
                      <small className="text-danger align-text-start">
                        {errors.website.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Hours</Form.Label>
                      <Form.Control
                        placeholder="Monday - Friday, 5-10pm"
                        isInvalid={!!errors.hours}
                        {...register("hours")}
                        data-test="hours-input"
                      />
                    </Form.Group>
                    {errors.hours && (
                      <small className="text-danger align-text-start">
                        {errors.hours.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Difficulty</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.ranking}
                        {...register("ranking")}
                        data-test="ranking-input"
                      />
                    </Form.Group>
                    {errors.ranking && (
                      <small className="text-danger align-text-start">
                        {errors.ranking.message}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row className="r-form-item adrs">
                  <Col className="r-form-rht">
                    <Col className="d-flex flex-column align-items-start">
                      <Form.Group className="align-self-stretch">
                        <Form.Label>Team Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          isInvalid={!!errors.about}
                          {...register("about")}
                          data-test="about-input"
                        ></Form.Control>
                      </Form.Group>
                      {errors.about && (
                        <small className="text-danger align-text-start">
                          {errors.about.message}
                        </small>
                      )}
                    </Col>
                  </Col>

                  <Col className="r-form-lft">
                    <Col className="d-flex flex-column align-items-start">
                      <Form.Group className="align-self-stretch">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                          name="website"
                          className="web-link"
                          type="text"
                          placeholder="ex: http://instagram.com/bball1"
                          isInvalid={!!errors.gender}
                          {...register("gender")}
                          data-test="gender-input"
                        >
                          <option value="boys">Boys</option>
                          <option value="girls">Girls</option>
                          <option value="both">Both</option>
                        </Form.Select>
                      </Form.Group>
                      {errors.gender && (
                        <small className="text-danger align-text-start">
                          {errors.gender.message}
                        </small>
                      )}
                    </Col>

                    <Col className="d-flex flex-column align-items-start">
                      <Form.Group className="align-self-stretch">
                        <Form.Label>Age Range</Form.Label>
                        <Form.Select
                          isInvalid={!!errors.ageRange}
                          {...register("ageRange")}
                          data-test="age-range-input"
                        >
                          <option value="">Select</option>
                          <option value="4-8">4-8</option>
                          <option value="10-14">10-14</option>
                          <option value="14-18">14-18</option>
                        </Form.Select>
                      </Form.Group>

                      {errors.ageRange && (
                        <small className="text-danger align-text-start">
                          {errors.ageRange.message}
                        </small>
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row className="r-form-list">
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Team Cost</Form.Label>
                      <Form.Control
                        placeholder="$200/Month"
                        isInvalid={!!errors.teamCost}
                        {...register("teamCost")}
                        data-test="team-cost-input"
                      />
                    </Form.Group>
                    {errors.teamCost && (
                      <small className="text-danger align-text-start">
                        {errors.teamCost.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Team Type</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.teamType}
                        placeholder="AAU Travel Team"
                        {...register("teamType")}
                        data-test="team-type-input"
                      />
                    </Form.Group>
                    {errors.teamType && (
                      <small className="text-danger align-text-start">
                        {errors.teamType.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Tryouts</Form.Label>
                      <Form.Control
                        placeholder="Date and location"
                        isInvalid={!!errors.tryouts}
                        {...register("tryouts")}
                        data-test="tryouts-input"
                      />
                    </Form.Group>
                    {errors.tryouts && (
                      <small className="text-danger align-text-start">
                        {errors.tryouts.message}
                      </small>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className="d-flex flex-column align-items-start r-form-item"
                  >
                    <Form.Group className="align-self-stretch">
                      <Form.Label>Gym Space</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.gymSpace}
                        placeholder="School or Location Name"
                        {...register("gymSpace")}
                        data-test="gym-space-input"
                      />
                    </Form.Group>
                    {errors.gymSpace && (
                      <small className="text-danger align-text-start">
                        {errors.gymSpace.message}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row className="t-btn">
                  <Col md={12} className="f-prfl-btns">
                    <Button
                      variant="primary"
                      // type="submit"
                      className="cncl-btn"
                    >
                      cancel
                    </Button>
                    <Button
                      form="trainer-signup-form"
                      variant="primary"
                      type="submit"
                      className="go-btn"
                    >
                      Let’s Go!
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <ChatbotFloatingButton />
        <Footer color={footerColor} />
      </div>
    </div>
  );
}
