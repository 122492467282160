import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import UserIcon from "../assets/images/icons/user-icon.svg";
import LogoIcon from "../assets/images/logo-icon.svg";
import PaperPlaneRightIcon from "../assets/images/icons/paper-plane-right-icon.svg";
import { tabTitle } from "../components/tabsTitle";
import authContext from "../store/authContext";
import ChatLoadingServer from "../components/chatLoadingServer";

const SAMPLE_MESSAGES = [
  "Help me find a team for my child",
  "Help me schedule an appointment with a trainer",
];

export default function ChatbotPage() {
  tabTitle("Chatbot");
  const footerColor = "#fff";
  const [messages, setMessages] = useState([
    {
      who: "bot",
      message: "Hi, I'm Terry! Welcome to GameUp!",
    },
  ]);
  const [userInput, setUserInput] = useState("");
  const [sampleMessageInput, setSampleMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [socket, setSocket] = useState(null);
  const [messageEnded, setMessageEnded] = useState(false);
  const [userHasSentMessage, setUserHasSentMessage] = useState(false);
  const chatbotRef = useRef(null);
  const { authenticated } = useContext(authContext);

  useEffect(() => {
    const socket = new WebSocket(
      "wss://4m4v4147zk.execute-api.us-east-1.amazonaws.com/production"
    );
    setSocket(socket);
    socket.addEventListener("message", (event) => {
      const lastMessage = event;
      if (lastMessage !== null) {
        var messageData = JSON.parse(lastMessage.data)["message"];
        if (messageData === "<START>") {
          handleEmptyMessage();
          startNewMessage("");
          return;
        }

        if (handleTimedOutRequest(messageData)) return;

        setIsThinking(false);

        if (!messageEnded) {
          appendMessage(messageData);
        } else {
          startNewMessage(messageData);
        }
      }
    });
  }, []);

  useEffect(() => {
    handleScrollChatbotToBottom();
  }, [messages]);

  const handleEmptyMessage = () => {
    setMessageEnded((prevState) => !prevState);
  };

  const handleTimedOutRequest = (message) => {
    if (message.includes("request timed out")) return true;
    return false;
  };

  const appendMessage = (message) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastIndex = updatedMessages.length - 1;
      if (lastIndex >= 0) {
        updatedMessages[lastIndex] = {
          ...updatedMessages[lastIndex],
          message: updatedMessages[lastIndex].message + message,
        };
      } else {
        updatedMessages.push({ who: "bot", message: message });
      }
      return updatedMessages;
    });
  };

  const startNewMessage = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { who: "bot", message: message },
    ]);
    setMessageEnded(false);
  };

  const conversationToMessages = (conversation) => {
    let parsedData = conversation.map((item) => {
      return {
        who: item.type === "human" ? "user" : "bot",
        message: item.data.content,
      };
    });
    return parsedData;
  };

  useEffect(() => {
    const fetchMessages = async () => {
      // Check if the conversation endpoint has any messages to load. If so, load them.
      const user = await Auth.currentCredentials().catch((err) =>
        console.log(err)
      );
      const sessionId = user.identityId;
      const conversation_endpoint = `https://d6odwpsicl636h6t6nexto5rjq0saybv.lambda-url.us-east-1.on.aws/${sessionId}`;
      try {
        const response = await fetch(conversation_endpoint);
        const data = await response.json();
        console.log(data);
        if (data.messages) {
          const messageHistory = conversationToMessages(data.messages.History);
          setMessages(messageHistory);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchMessages();
  }, []);

  const handleSendMessage = async (message) => {
    if (!message.trim()) return;

    setUserHasSentMessage(true);

    const newMessage = { who: "user", message };
    setMessages([...messages, newMessage]);
    setUserInput("");

    setIsThinking(true);

    try {
      const user = await Auth.currentCredentials().catch((err) =>
        console.log(err)
      );
      const payload = {
        messageBody: message,
        phone_number: user.identityId,
      };
      setMessageEnded(true);
      socket.send(JSON.stringify(payload));
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage(userInput);
    }
  };

  const handleSendSampleMessage = () => {
    if (!sampleMessageInput.trim()) return;

    handleSendMessage(sampleMessageInput);
  };

  const handleScrollChatbotToBottom = () => {
    if (chatbotRef.current) {
      chatbotRef.current.scrollTo({
        top: chatbotRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    handleSendSampleMessage();
  }, [sampleMessageInput]);

  useEffect(() => {
    handleScrollChatbotToBottom();
  }, [messages]);

  return (
    <div id="wrapper">
      <div className="chatbot-main">
        <Container>
          <Row>
            <Col className="chatbot-cnt">
              <h2>Talk with Terry</h2>
              <p>
                Introducing Terry, your amicable AI Game Up Assistant, here to
                help with any inquiries you might have. If you're unable to find
                the information you need, simply ask Terry to connect you with a
                member of our team.
              </p>
            </Col>
          </Row>
        </Container>

        {!authenticated && (
          <Container>
            <Row>
              <Col className="chat-bot-btns">
                <Link to="/login" className="login-btn">
                  Log In
                </Link>
                <Link to="/joingame" className="crct-acnt">
                  Create an account
                </Link>
              </Col>
            </Row>
          </Container>
        )}
        <Container>
          <div className="chatbox d-flex flex-column">
            <Row
              ref={chatbotRef}
              className={`main-chat ${
                userHasSentMessage && "first-message-sent"
              }`}
            >
              {messages.map((message, index) => (
                <Row key={index}>
                  <Col
                    md={12}
                    className={`chat-item ${
                      message.who === "user" ? "chat-user" : "chat-bot"
                    }`}
                  >
                    <div className="chat-dp">
                      <img src={message.who === "user" ? UserIcon : LogoIcon} />
                    </div>
                    <div className="chat-cnt">
                      {message.who === "bot" ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: message.message,
                          }}
                        />
                      ) : (
                        <p>{message.message}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
              {isThinking && (
                <Row>
                  <Col md={12} className="chat-item">
                    <ChatLoadingServer />
                  </Col>
                </Row>
              )}

              <Row className="d-flex w-100 justify-content-center">
                <ClipLoader
                  color={"#ff8e15"}
                  loading={isLoading}
                  size={64}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Row>
            </Row>
            <div className="ex-chat-list">
              <Row>
                {!userHasSentMessage && (
                  <Col md={12} className="ex-ul">
                    {SAMPLE_MESSAGES.map((message, idx) => (
                      <p key={message.trim()}>
                        <button
                          style={{ cursor: "pointer" }}
                          className="login-btn"
                          onClick={() => setSampleMessageInput(message)}
                          data-test={`sample-message-${idx + 1}`}
                        >
                          {message}
                        </button>
                      </p>
                    ))}
                  </Col>
                )}
              </Row>
            </div>
            <div className="enter-txt mt-auto">
              <Form>
                <Row>
                  <Col className="txt-box">
                    <Form.Control
                      as="textarea"
                      placeholder="Your message..."
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      onKeyDown={handleKeyPress}
                      data-test="message-input"
                    />
                    <div
                      className="enter-btn-c"
                      onClick={() => handleSendMessage(userInput)}
                      data-test="send-message-button"
                    >
                      <img src={PaperPlaneRightIcon} />
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Container>
        <Footer color={footerColor} />
      </div>
    </div>
  );
}
