/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAAUTeam = /* GraphQL */ `
  query GetAAUTeam($id: ID!) {
    getAAUTeam(id: $id) {
      id
      name
      banner
      ranking
      sponsorship
      website
      minimumAge
      maximumAge
      gender
      street
      city
      state
      zipcode
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
        }
        nextToken
        startedAt
      }
      facebookPageUrl
      instagramPageUrl
      schedule
      email
      phoneNumber
      difficulty
      teamType
      league
      tryouts
      tryoutLocation
      gymSpace
      teamCost
      about
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listAAUTeams = /* GraphQL */ `
  query ListAAUTeams(
    $filter: ModelAAUTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAAUTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        banner
        ranking
        sponsorship
        website
        street
        city
        state
        zipcode
        coaches {
          nextToken
          startedAt
        }
        facebookPageUrl
        instagramPageUrl
        schedule
        email
        phoneNumber
        difficulty
        teamType
        league
        tryouts
        tryoutLocation
        gymSpace
        teamCost
        about
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAAUTeams = /* GraphQL */ `
  query SyncAAUTeams(
    $filter: ModelAAUTeamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAAUTeams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        banner
        ranking
        sponsorship
        website
        minimumAge
        maximumAge
        gender
        street
        city
        state
        zipcode
        coaches {
          nextToken
          startedAt
        }
        facebookPageUrl
        instagramPageUrl
        schedule
        email
        phoneNumber
        difficulty
        teamType
        league
        tryouts
        tryoutLocation
        gymSpace
        teamCost
        about
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
      id
      name
      teamID
      costOfTraining
      email
      userName
      avatar
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listCoaches = /* GraphQL */ `
  query ListCoaches(
    $filter: ModelCoachFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        teamID
        costOfTraining
        email
        userName
        avatar
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCoaches = /* GraphQL */ `
  query SyncCoaches(
    $filter: ModelCoachFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCoaches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        teamID
        costOfTraining
        email
        userName
        avatar
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getSkillsTrainer = /* GraphQL */ `
  query GetSkillsTrainer($id: ID!) {
    getSkillsTrainer(id: $id) {
      id
      name
      contactInfo
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listSkillsTrainers = /* GraphQL */ `
  query ListSkillsTrainers(
    $filter: ModelSkillsTrainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillsTrainers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactInfo
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSkillsTrainers = /* GraphQL */ `
  query SyncSkillsTrainers(
    $filter: ModelSkillsTrainerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSkillsTrainers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        contactInfo
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getCamp = /* GraphQL */ `
  query GetCamp($id: ID!) {
    getCamp(id: $id) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listCamps = /* GraphQL */ `
  query ListCamps(
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCamps = /* GraphQL */ `
  query SyncCamps(
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCamps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getPreschoolProgram = /* GraphQL */ `
  query GetPreschoolProgram($id: ID!) {
    getPreschoolProgram(id: $id) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listPreschoolPrograms = /* GraphQL */ `
  query ListPreschoolPrograms(
    $filter: ModelPreschoolProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreschoolPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        website
        schedule
        coaches {
          nextToken
          startedAt
        }
        minimumAge
        maximumAge
        location
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPreschoolPrograms = /* GraphQL */ `
  query SyncPreschoolPrograms(
    $filter: ModelPreschoolProgramFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPreschoolPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        schedule
        coaches {
          nextToken
          startedAt
        }
        minimumAge
        maximumAge
        location
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getDevelopmentProgram = /* GraphQL */ `
  query GetDevelopmentProgram($id: ID!) {
    getDevelopmentProgram(id: $id) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listDevelopmentPrograms = /* GraphQL */ `
  query ListDevelopmentPrograms(
    $filter: ModelDevelopmentProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevelopmentPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        website
        schedule
        coaches {
          nextToken
          startedAt
        }
        minimumAge
        maximumAge
        location
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDevelopmentPrograms = /* GraphQL */ `
  query SyncDevelopmentPrograms(
    $filter: ModelDevelopmentProgramFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevelopmentPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        schedule
        coaches {
          nextToken
          startedAt
        }
        minimumAge
        maximumAge
        location
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getBasketballSchool = /* GraphQL */ `
  query GetBasketballSchool($id: ID!) {
    getBasketballSchool(id: $id) {
      id
      name
      website
      type
      location
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listBasketballSchools = /* GraphQL */ `
  query ListBasketballSchools(
    $filter: ModelBasketballSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBasketballSchools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        website
        type
        location
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBasketballSchools = /* GraphQL */ `
  query SyncBasketballSchools(
    $filter: ModelBasketballSchoolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBasketballSchools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        type
        location
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getRehab = /* GraphQL */ `
  query GetRehab($id: ID!) {
    getRehab(id: $id) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listRehabs = /* GraphQL */ `
  query ListRehabs(
    $filter: ModelRehabFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRehabs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRehabs = /* GraphQL */ `
  query SyncRehabs(
    $filter: ModelRehabFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRehabs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getLeague = /* GraphQL */ `
  query GetLeague($id: ID!) {
    getLeague(id: $id) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listLeagues = /* GraphQL */ `
  query ListLeagues(
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeagues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLeagues = /* GraphQL */ `
  query SyncLeagues(
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeagues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getTournament = /* GraphQL */ `
  query GetTournament($id: ID!) {
    getTournament(id: $id) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const listTournaments = /* GraphQL */ `
  query ListTournaments(
    $filter: ModelTournamentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTournaments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTournaments = /* GraphQL */ `
  query SyncTournaments(
    $filter: ModelTournamentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTournaments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        website
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
