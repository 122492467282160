/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAAUTeam = /* GraphQL */ `
  mutation CreateAAUTeam(
    $input: CreateAAUTeamInput!
    $condition: ModelAAUTeamConditionInput
  ) {
    createAAUTeam(input: $input, condition: $condition) {
      id
      name
      banner
      ranking
      sponsorship
      website
      minimumAge
      maximumAge
      gender
      street
      city
      state
      zipcode
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      facebookPageUrl
      instagramPageUrl
      schedule
      email
      phoneNumber
      difficulty
      teamType
      league
      tryouts
      tryoutLocation
      gymSpace
      teamCost
      about
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAAUTeam = /* GraphQL */ `
  mutation UpdateAAUTeam(
    $input: UpdateAAUTeamInput!
    $condition: ModelAAUTeamConditionInput
  ) {
    updateAAUTeam(input: $input, condition: $condition) {
      id
      name
      banner
      ranking
      sponsorship
      website
      minimumAge
      maximumAge
      gender
      street
      city
      state
      zipcode
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      facebookPageUrl
      instagramPageUrl
      schedule
      email
      phoneNumber
      difficulty
      teamType
      league
      tryouts
      tryoutLocation
      gymSpace
      teamCost
      about
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAAUTeam = /* GraphQL */ `
  mutation DeleteAAUTeam(
    $input: DeleteAAUTeamInput!
    $condition: ModelAAUTeamConditionInput
  ) {
    deleteAAUTeam(input: $input, condition: $condition) {
      id
      name
      banner
      ranking
      sponsorship
      website
      minimumAge
      maximumAge
      gender
      street
      city
      state
      zipcode
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      facebookPageUrl
      instagramPageUrl
      schedule
      email
      phoneNumber
      difficulty
      teamType
      league
      tryouts
      tryoutLocation
      gymSpace
      teamCost
      about
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      name
      teamID
      costOfTraining
      email
      userName
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      name
      teamID
      costOfTraining
      email
      userName
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCoach = /* GraphQL */ `
  mutation DeleteCoach(
    $input: DeleteCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    deleteCoach(input: $input, condition: $condition) {
      id
      name
      teamID
      costOfTraining
      email
      userName
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSkillsTrainer = /* GraphQL */ `
  mutation CreateSkillsTrainer(
    $input: CreateSkillsTrainerInput!
    $condition: ModelSkillsTrainerConditionInput
  ) {
    createSkillsTrainer(input: $input, condition: $condition) {
      id
      name
      contactInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSkillsTrainer = /* GraphQL */ `
  mutation UpdateSkillsTrainer(
    $input: UpdateSkillsTrainerInput!
    $condition: ModelSkillsTrainerConditionInput
  ) {
    updateSkillsTrainer(input: $input, condition: $condition) {
      id
      name
      contactInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSkillsTrainer = /* GraphQL */ `
  mutation DeleteSkillsTrainer(
    $input: DeleteSkillsTrainerInput!
    $condition: ModelSkillsTrainerConditionInput
  ) {
    deleteSkillsTrainer(input: $input, condition: $condition) {
      id
      name
      contactInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCamp = /* GraphQL */ `
  mutation CreateCamp(
    $input: CreateCampInput!
    $condition: ModelCampConditionInput
  ) {
    createCamp(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCamp = /* GraphQL */ `
  mutation UpdateCamp(
    $input: UpdateCampInput!
    $condition: ModelCampConditionInput
  ) {
    updateCamp(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCamp = /* GraphQL */ `
  mutation DeleteCamp(
    $input: DeleteCampInput!
    $condition: ModelCampConditionInput
  ) {
    deleteCamp(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPreschoolProgram = /* GraphQL */ `
  mutation CreatePreschoolProgram(
    $input: CreatePreschoolProgramInput!
    $condition: ModelPreschoolProgramConditionInput
  ) {
    createPreschoolProgram(input: $input, condition: $condition) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePreschoolProgram = /* GraphQL */ `
  mutation UpdatePreschoolProgram(
    $input: UpdatePreschoolProgramInput!
    $condition: ModelPreschoolProgramConditionInput
  ) {
    updatePreschoolProgram(input: $input, condition: $condition) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePreschoolProgram = /* GraphQL */ `
  mutation DeletePreschoolProgram(
    $input: DeletePreschoolProgramInput!
    $condition: ModelPreschoolProgramConditionInput
  ) {
    deletePreschoolProgram(input: $input, condition: $condition) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDevelopmentProgram = /* GraphQL */ `
  mutation CreateDevelopmentProgram(
    $input: CreateDevelopmentProgramInput!
    $condition: ModelDevelopmentProgramConditionInput
  ) {
    createDevelopmentProgram(input: $input, condition: $condition) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDevelopmentProgram = /* GraphQL */ `
  mutation UpdateDevelopmentProgram(
    $input: UpdateDevelopmentProgramInput!
    $condition: ModelDevelopmentProgramConditionInput
  ) {
    updateDevelopmentProgram(input: $input, condition: $condition) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDevelopmentProgram = /* GraphQL */ `
  mutation DeleteDevelopmentProgram(
    $input: DeleteDevelopmentProgramInput!
    $condition: ModelDevelopmentProgramConditionInput
  ) {
    deleteDevelopmentProgram(input: $input, condition: $condition) {
      id
      name
      website
      schedule
      coaches {
        items {
          id
          name
          teamID
          costOfTraining
          email
          userName
          avatar
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      minimumAge
      maximumAge
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBasketballSchool = /* GraphQL */ `
  mutation CreateBasketballSchool(
    $input: CreateBasketballSchoolInput!
    $condition: ModelBasketballSchoolConditionInput
  ) {
    createBasketballSchool(input: $input, condition: $condition) {
      id
      name
      website
      type
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBasketballSchool = /* GraphQL */ `
  mutation UpdateBasketballSchool(
    $input: UpdateBasketballSchoolInput!
    $condition: ModelBasketballSchoolConditionInput
  ) {
    updateBasketballSchool(input: $input, condition: $condition) {
      id
      name
      website
      type
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBasketballSchool = /* GraphQL */ `
  mutation DeleteBasketballSchool(
    $input: DeleteBasketballSchoolInput!
    $condition: ModelBasketballSchoolConditionInput
  ) {
    deleteBasketballSchool(input: $input, condition: $condition) {
      id
      name
      website
      type
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRehab = /* GraphQL */ `
  mutation CreateRehab(
    $input: CreateRehabInput!
    $condition: ModelRehabConditionInput
  ) {
    createRehab(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRehab = /* GraphQL */ `
  mutation UpdateRehab(
    $input: UpdateRehabInput!
    $condition: ModelRehabConditionInput
  ) {
    updateRehab(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRehab = /* GraphQL */ `
  mutation DeleteRehab(
    $input: DeleteRehabInput!
    $condition: ModelRehabConditionInput
  ) {
    deleteRehab(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLeague = /* GraphQL */ `
  mutation CreateLeague(
    $input: CreateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    createLeague(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLeague = /* GraphQL */ `
  mutation UpdateLeague(
    $input: UpdateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    updateLeague(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLeague = /* GraphQL */ `
  mutation DeleteLeague(
    $input: DeleteLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    deleteLeague(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTournament = /* GraphQL */ `
  mutation CreateTournament(
    $input: CreateTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    createTournament(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTournament = /* GraphQL */ `
  mutation UpdateTournament(
    $input: UpdateTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    updateTournament(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTournament = /* GraphQL */ `
  mutation DeleteTournament(
    $input: DeleteTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    deleteTournament(input: $input, condition: $condition) {
      id
      name
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
