import { useState } from "react";
import { Auth } from "aws-amplify";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { tabTitle } from "../components/tabsTitle";
import { ClipLoader } from "react-spinners";

export default function ForgotPage() {
  const navigate = useNavigate();
  tabTitle("Forgot Password");
  const [payload, setpayload] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setpayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onClick = async () => {
    try {
      setLoading(true);
      const login = await Auth.forgotPassword(payload.username);
      setLoading(false);
      if (login) {
        navigate("/setpassword", {
          state: { username: payload.username },
        });
      }
    } catch (e) {
      setLoading(false);
      alert(e.message);
    }
  };

  const handleSubmit = () => (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <>
      <div className="login-page pb-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="forms">
                <h1 className="py-5 mt-0 mt-md-5 mb-3">Forgot password</h1>
                <Form className="login-form" onSubmit={handleSubmit()}>
                  <Row className="form-inputs mx-auto py-4">
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="pt-2">Please enter your email</Form.Label>
                        <Form.Control
                          name="username"
                          type="email"
                          placeholder="Email"
                          onChange={onChange}
                          data-test="username-input"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      {loading? 
                      // Inline styling for centered loader
                      <div className="loader">
                      <ClipLoader
                        color={"#ffffff"}
                        loading={loading}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      /> 
                      </div>
                      :
                      <Button
                        variant="primary"
                        className="form-btn mt-2"
                        onClick={onClick}
                        data-test="submit-button"
                      >
                        Send email
                      </Button>}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
