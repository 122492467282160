import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import Geocode from "react-geocode";
import ResultsSlider from "../components/resultsSlider";
import { tabTitle } from "../components/tabsTitle";
import { Link, useLocation } from "react-router-dom";
import FilterCross from "../assets/images/icons/filter-cross.svg";
import arrowLeftIcon from "../assets/images/icons/back-arrow.svg";
import saveIcon from "../assets/images/icons/save-icon.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import gridIcon from "../assets/images/icons/grid-icon.svg";
import Cards from "../components/cards";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import GameUpStreams from "../components/GameupStreams";
import ChatbotFloatingButton from "../components/chatbotFloatingButton";

import MapWithMarkers from "../components/MapWithMarkers";
import useGeolocation from "../hooks/useGeolocation";
import { Fragment } from "react";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage("en");

const center = {
  lat: 40.732895,
  lng: -73.99599,
};

async function getCoordinatesFromZipCode(zipCode) {
  try {
    const { results } = await Geocode.fromAddress(zipCode + " USA");

    if (results.length > 0) {
      const { lat, lng } = results[0].geometry.location;
      return { lat, lng };
    }

    return null;
  } catch (e) {
    return null;
  }
}

async function addCoordToTeams(aauTeams) {
  const teamsWithCoords = await Promise.all(
    aauTeams.map(async (team) => {
      if (team.zipcode) {
        const coordinates = await getCoordinatesFromZipCode(team.zipcode);
        return { ...team, coordinates };
      }
      return team;
    })
  );

  return teamsWithCoords;
}

function groupTeamsByCity(aauTeams, numOfCitiesToShow = 3) {
  const groupedTeams = {};

  if (aauTeams.length === 0) {
    return groupedTeams;
  }

  const firstTeamGender = aauTeams[0].gender;

  aauTeams.forEach((team) => {
    // Filter out teams that don't have the same gender as the first team
    // if (team?.gender == null) {
    // } else if (team?.gender == "both" || team?.gender == "BOTH") {
    // } else if (team.gender !== firstTeamGender) {
    //   // console.log(`${team?.gender}, ${firstTeamGender}`);
    //   // console.log(`${team.city}=="Brooklyn"`);
    //   // return;
    // }

    const city = team.city;
    if (!groupedTeams[city]) {
      groupedTeams[city] = [];
    }
    groupedTeams[city].push(team);
  });

  // Prioritize the five boroughs of NYC
  const nycBoroughs = [
    "Manhattan",
    "Brooklyn",
    "Queens",
    "Bronx",
    "Staten Island",
  ];

  // Create an array of city keys in their original order
  const orderedCities = aauTeams
    .map((team) => team.city)
    .filter((city, index, self) => self.indexOf(city) === index);

  // Check if the first city is within the five boroughs
  const firstCityIsBorough = nycBoroughs.includes(orderedCities[0]);

  // Maintain the original order of the cities
  const sortedCityKeys = [...orderedCities];

  // If the first city is a borough, prioritize the boroughs
  if (firstCityIsBorough) {
    const boroughs = sortedCityKeys.filter((city) =>
      nycBoroughs.includes(city)
    );
    const otherCities = sortedCityKeys.filter(
      (city) => !nycBoroughs.includes(city)
    );
    sortedCityKeys.length = 0; // Clear the array
    sortedCityKeys.push(...boroughs, ...otherCities);
  }

  // Reconstruct the groupedTeams object with the sorted keys
  const sortedGroupedTeams = {};
  // sortedCityKeys.slice(0, numOfCitiesToShow).forEach((city) => {
  //   sortedGroupedTeams[city] = groupedTeams[city];
  // });
  sortedCityKeys.forEach((city) => {
    sortedGroupedTeams[city] = groupedTeams[city];
  });

  return sortedGroupedTeams;
}

function distanceBetweenCoords(coords1, coords2) {
  //This function applies the Haversine formula to calculate the distance between two points on a sphere

  var R = 3958.8;
  var rlat1 = coords1.lat * (Math.PI / 180);
  var rlat2 = coords2.lat * (Math.PI / 180);
  var difflat = rlat2 - rlat1;
  var difflon = (coords2.lng - coords1.lng) * (Math.PI / 180);

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return d;
}

function sortTeamsByDistance(team1, team2, userCoordinates) {
  const team1HasCoordinates = !!team1.coordinates;
  const team2HasCoordinates = !!team2.coordinates;

  // Handle teams without coordinates
  if (!team1HasCoordinates && !team2HasCoordinates) {
    return 0; // No change in order for teams without coordinates
  } else if (!team1HasCoordinates) {
    return 1; // Team 1 is missing coordinates, place it after Team 2
  } else if (!team2HasCoordinates) {
    return -1; // Team 2 is missing coordinates, place it after Team 1
  }

  // Both teams have coordinates, calculate distances
  const distance1 = distanceBetweenCoords(userCoordinates, team1.coordinates);
  const distance2 = distanceBetweenCoords(userCoordinates, team2.coordinates);
  return distance1 - distance2;
}

const viewOptions = [
  {
    value: "grid",
    icon: gridIcon,
    alt: "Grid View",
  },
  {
    value: "map",
    icon: mapIcon,
    alt: "Map View",
  },
];

const sortingOptions = [
  {
    value: "best-match",
    label: "Best Match",
  },
  {
    value: "distance",
    label: "Distance",
  },
  {
    value: "a-z",
    label: "Alphabetical, A-Z",
  },
  {
    value: "z-a",
    label: "Alphabetical, Z-A",
  },
];

export default function QueryForm() {
  tabTitle("Results");
  const { state } = useLocation();
  const { aauTeams: initialTeams, filters: initialFilters } = state;
  const [selectedView, setSelectedView] = useState("grid");
  const [aauTeams, setAauTeams] = useState(initialTeams);
  const [filteredTeams, setFilteredTeams] = useState(aauTeams);
  const [filters, setFilters] = useState(initialFilters);
  const [seeMore, setSeeMore] = useState(false);
  const [selectedSortingMethod, setSelectedSortingMethod] = useState(
    "best-match"
  );
  const [groupedTeams, setGroupedTeams] = useState({});
  const [markersData, setMarkersData] = useState([]);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const { permissionState, location } = useGeolocation();

  const handleFilterItems = useCallback(() => {
    const filteredItems = aauTeams
      .filter((item) =>
        filters?.age
          ? Number(filters.age) >= item.minimumAge &&
            Number(filters.age) <= item.maximumAge
          : true
      )
      // .filter((item) =>
      //   filters?.city
      //     ? item.city.toLowerCase().includes(filters.city.toLowerCase())
      //     : true
      // )
      .filter((item) => {
        return filters?.gender
          ? filters.gender === "male"
            ? item.gender?.toLowerCase() === "boys" ||
              item.gender === null ||
              item.gender?.toLowerCase() === "both"
            : item.gender?.toLowerCase() === "girls" ||
              item.gender === null ||
              item.gender?.toLowerCase() === "both"
          : true;
      });
    setFilteredTeams(filteredItems);
  }, [filters, aauTeams]);

  const handleSortFilteredItems = () => {
    switch (selectedSortingMethod) {
      case "best-match":
        setGroupedTeams(groupTeamsByCity(filteredTeams));
        break;
      case "distance": {
        const sortedGroupedTeams = { ...groupTeamsByCity(filteredTeams) };
        Object.keys(sortedGroupedTeams).forEach((city) => {
          sortedGroupedTeams[city].sort((a, b) =>
            sortTeamsByDistance(a, b, location)
          );
        });

        setGroupedTeams(sortedGroupedTeams);
        break;
      }
      case "a-z":
        const sortedGroupedTeams = { ...groupTeamsByCity(filteredTeams) };
        Object.keys(sortedGroupedTeams).forEach((city) => {
          sortedGroupedTeams[city].sort((a, b) => a.name.localeCompare(b.name));
        });
        setGroupedTeams(sortedGroupedTeams);
        break;
      case "z-a":
        const sortedGroupedTeams2 = { ...groupTeamsByCity(filteredTeams) };
        Object.keys(sortedGroupedTeams2).forEach((city) => {
          sortedGroupedTeams2[city].sort((a, b) =>
            b.name.localeCompare(a.name)
          );
        });
        setGroupedTeams(sortedGroupedTeams2);
        break;
      default:
        break;
    }
  };

  const removeFilterValue = (key) => {
    const newFilter = { ...filters };

    newFilter[key] = "";

    setFilters(newFilter);
  };

  useEffect(() => {
    handleFilterItems();
  }, [filters, handleFilterItems]);

  useEffect(() => {
    setGroupedTeams(groupTeamsByCity(filteredTeams));
  }, [filteredTeams]);

  useEffect(() => {
    handleSortFilteredItems();
  }, [selectedSortingMethod, filteredTeams]);

  useEffect(() => {
    // Update the zipCodes state only when aauTeams changes
    const _markersData = [];
    Object.entries(groupedTeams).forEach(([city, cityTeams]) => {
      cityTeams.forEach((team, index) => {
        if (team.zipcode !== null) {
          _markersData.push({
            city,
            index,
            lat: team?.coordinates?.lat,
            lng: team?.coordinates?.lng,
          });
        }
      });
    });
    setMarkersData(_markersData);
  }, [filteredTeams, groupedTeams]);

  useEffect(() => {
    addCoordToTeams(initialTeams).then((teams) => setAauTeams(teams));
  }, []);

  return (
    <>
      <ChatbotFloatingButton />
      <div className="form-layouts py-5 results-page">
        <Container>
          <Row>
            <Col md={12} className="position-relative">
              <div className="forms py-2">
                <h1 className="py-5">
                  Here are the teams we found
                  <br />
                  <span className="text-black"></span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="filters-section">
        <Container>
          <Col md={12}>
            <Row>
              <div className="d-flex justify-content-lg-start justify-content-between gap-5 filter-bar-controls">
                <Link
                  to="/query"
                  className="d-flex gap-3 align-items-center text-uppercase position-relative"
                >
                  <img src={arrowLeftIcon} />
                  edit search
                </Link>
                <Button className="d-flex gap-3 align-items-center text-uppercase position-relative">
                  <img src={saveIcon} />

                  {isMobile ? "Save" : "Save this search"}
                </Button>
              </div>
            </Row>
          </Col>
        </Container>
        <div className="separator w-100" />
        <Container>
          <Col>
            <Row>
              <div className="mt-2 d-flex justify-content-between align-items-lg-center flex-lg-row flex-column gap-lg-0 gap-4">
                <div className="d-flex align-items-lg-center">
                  <span className="text-black fw-semibold me-3">FILTERS:</span>
                  <div className="d-flex align-items-center gap-2 badges-list">
                    {Object.keys(filters)
                      .filter((item) => !!filters[item])
                      .map((key, idx) => (
                        <span
                          key={idx}
                          className="filter-select-val d-flex align-items-center text-capitalize"
                        >
                          {filters[key]} {key === "age" && " years old"}
                          <Badge
                            bg=""
                            className="pe-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => removeFilterValue(key)}
                          >
                            <img
                              className=""
                              src={FilterCross}
                              alt="FilterCross"
                            />
                          </Badge>
                        </span>
                      ))}
                  </div>
                </div>
                <div className="d-flex align-items-lg-center filter-selectors flex-lg-row flex-column">
                  <div className="d-flex align-items-center">
                    <span className="text-black fw-semibold me-3">VIEW:</span>
                    <div className="d-flex align-items-center gap-1">
                      {viewOptions.map((item) => (
                        <button
                          key={item.value}
                          onClick={() => setSelectedView(item.value)}
                          className={
                            "border-0 view-button" +
                            (selectedView === item.value ? " active" : "")
                          }
                        >
                          <img src={item.icon} alt={item.alt} />
                        </button>
                      ))}
                    </div>
                  </div>
                  {selectedView !== "map" && (
                    <div className="d-flex align-items-center">
                      <span className="text-black fw-semibold me-3">SORT:</span>
                      <Form.Select
                        id="dropdown-basic-button"
                        className="dropdown-basic-button"
                        title="Select..."
                        value={selectedSortingMethod}
                        onChange={(e) =>
                          setSelectedSortingMethod(e.target.value)
                        }
                      >
                        {sortingOptions
                          .filter((item) =>
                            permissionState === "granted"
                              ? item
                              : item.value !== "distance"
                          )
                          .map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Container>
      </div>

      {selectedView === "map" ? (
        <MapWithMarkers markersData={markersData} groupedTeams={groupedTeams} />
      ) : (
        <div className="pt-5 my-5">
          <Container>
            <Row>
              <Col
                md={12}
                className="results-slider-section"
                style={{ marginBottom: 0 }}
              >
                {aauTeams.length > 0 ? (
                  Object.keys(groupedTeams)
                    .filter((_, idx) => (seeMore ? true : idx < 2))
                    .map((city) => (
                      <Fragment key={city}>
                        <Col
                          key={`${city}-title`}
                          md={12}
                          className="results-page-description position-relative"
                        >
                          <h4 className="text-center text-lg-start">{city}</h4>
                        </Col>
                        <Col
                          key={`${city}-slider`}
                          md={12}
                          className="results-slider-section"
                        >
                          <ResultsSlider
                            cardsData={groupedTeams[city]}
                            cardType="team"
                          />
                        </Col>
                      </Fragment>
                    ))
                ) : (
                  <>
                    <br />
                    <h4 className="text-center">No result found...</h4>
                  </>
                )}
              </Col>
            </Row>
            <button className="see-more" onClick={() => setSeeMore((prev) => !prev)}>
              {seeMore ? "See less" : "See more"}
            </button>
          </Container>
        </div>
      )}
      <GameUpStreams color="orange" />
    </>
  );
}
