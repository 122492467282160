import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Amplify, { Auth, Hub } from "aws-amplify";
import { ClipLoader } from "react-spinners";

import AuthContext from "./store/authContext";

import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/style.scss";
import "./assets/styles/new-pages.css";

import awsConfig from "./aws-exports";
import { ROUTES } from "./routes";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: "https://game-up.us/",
    redirectSignOut: "https://game-up.us/",
    // redirectSignIn: isLocalhost
    //   ? localRedirectSignIn
    //   : productionRedirectSignIn,
    // redirectSignOut: isLocalhost
    //   ? localRedirectSignOut
    //   : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

const Container = ({ isPrivate, isPublic, children }) => {
  const navigate = useNavigate();
  const [isAccess, setIsAccess] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    checkAuth();
  }, [isPrivate, isPublic]);

  const checkAuth = async () => {
    if (isPrivate) {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setAuthenticated(userData.attributes);
        setIsAccess(true);
      } catch (error) {
        setIsAccess(false);
        navigate("/login");
        console.log("eeeor: ", error);
      }
    } else if (isPublic) {
      const userData = await Auth.currentAuthenticatedUser().catch((err) => {});
      setAuthenticated(userData?.attributes);
      setIsAccess(true);
    } else {
      const res = await Auth.currentAuthenticatedUser().catch(() => {});
      if (res) {
        setIsAccess(false);
        navigate("/profile");
      } else {
        setIsAccess(true);
      }
    }
  };

  return isAccess ? (
    children
  ) : (
    <div className="loader">
      <ClipLoader
        color={"#ffffff"}
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "cognitoHostedUI":
          Auth.currentAuthenticatedUser().then((userData) => {
            setAuthenticated(userData?.attributes);
            navigate("/profile");
          });
          break;
        case "signOut":
          setAuthenticated(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      <div className="App">
        <ScrollToTop />
        <Header />
        <Routes>
          {ROUTES.map(
            ({
              Component,
              isPrivate,
              path,
              isPublic,
              disableDefaultFooter,
            }) => (
              <Route
                exact
                path={path}
                key={path}
                element={
                  <>
                    <Container isPrivate={isPrivate} isPublic={isPublic}>
                      <Component />
                    </Container>
                    {!disableDefaultFooter && <Footer />}
                  </>
                }
              />
            )
          )}
        </Routes>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
