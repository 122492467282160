import IndexPage from "./views";
import LoginPage from "./views/login";
import ForgotPage from "./views/forgot";
import SetPassword from "./views/setPassword";
import VerifyPage from "./views/verify";
import JoinGame from "./views/joinGame";
import About from "./views/about";
import Results from "./views/results";
import Profile from "./views/profile";
import PlayerDash from "./views/playerDash";
import Team from "./views/team";
import BasketballConcierge from "./views/basketballConcierge";
import AdminSettings from "./views/adminSettings";
import ChatbotPage from "./views/chatbot";
import SearchLanding from "./views/searchLanding";
import SearchMain from "./views/searchMain";
import TrainerSignUp from "./views/trainerSignUp";
import NewTeam from "./views/newTeam";
import Confirmation from "./views/confirmation";
import AccountSettings from "./views/accountSettings";
import ResultsTrainers from "./views/results-trainers";

export const ROUTES = [
  {
    path: "/",
    Component: IndexPage,
    isPrivate: false,
  },
  {
    path: "/login",
    isPrivate: false,
    Component: LoginPage,
  },
  {
    path: "/forgot",
    isPrivate: false,
    Component: ForgotPage,
  },
  {
    path: "/setpassword",
    isPrivate: false,
    Component: SetPassword,
  },
  {
    path: "/verify",
    isPrivate: false,
    Component: VerifyPage,
  },
  {
    path: "/joingame",
    isPrivate: false,
    Component: JoinGame,
    disableDefaultFooter: true,
  },
  {
    path: "/query",
    isPrivate: false,
    isPublic: true,
    Component: SearchMain,
    disableDefaultFooter: true,
  },
  {
    path: "/results",
    Component: Results,
    isPrivate: false,
    isPublic: true,
  },
  {
    path: "/profile",
    isPrivate: true,
    Component: Profile,
  },
  {
    path: "/player-dash",
    isPrivate: true,
    Component: PlayerDash,
  },
  {
    path: "/team/:id",
    isPrivate: false,
    isPublic: true,
    Component: Team,
  },
  {
    path: "/search",
    isPrivate: false,
    isPublic: true,
    Component: SearchMain,
    disableDefaultFooter: true,
  },
  {
    path: "/about",
    isPrivate: false,
    isPublic: true,
    Component: About,
  },
  {
    path: "/basketball-concierge",
    isPrivate: false,
    isPublic: true,
    Component: BasketballConcierge,
  },
  {
    path: "/admin-settings",
    isPrivate: false,
    isPublic: true,
    Component: AdminSettings,
  },
  {
    path: "/chatbot",
    isPrivate: false,
    isPublic: true,
    Component: ChatbotPage,
    disableDefaultFooter: true,
  },
  {
    path: "/search-landing",
    isPrivate: false,
    isPublic: true,
    Component: SearchLanding,
    disableDefaultFooter: true,
  },
  {
    path: "/search-main",
    isPrivate: false,
    isPublic: true,
    Component: SearchMain,
    disableDefaultFooter: true,
  },
  {
    path: "/trainer-signup",
    isPrivate: false,
    isPublic: true,
    Component: TrainerSignUp,
    disableDefaultFooter: true,
  },
  {
    path: "/new-team",
    isPrivate: true,
    isPublic: false,
    Component: NewTeam,
    disableDefaultFooter: true,
  },
  {
    path: "/confirmation",
    isPrivate: true,
    isPublic: false,
    Component: Confirmation,
    disableDefaultFooter: true,
  },
  {
    path: "account-settings",
    Component: AccountSettings,
    isPrivate: true,
    isPublic: false,
    disableDefaultFooter: true,
  },
  {
    path: "/results-trainers",
    Component: ResultsTrainers,
    isPrivate: false,
    isPublic: true,
  },
];
