import Modal from "react-bootstrap/Modal";

import { Col, Row } from "react-bootstrap";
import { PopupModal } from "react-calendly";
import { useState } from "react";

export default function TrainerDetailModal(props) {
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);

  const handleOpenCalendlyModal = () => {
    if (!props.calendly) return;

    setCalendlyModalOpen(true);
  };

  return (
    <>
      <PopupModal
        url={props.calendly}
        onModalClose={() => setCalendlyModalOpen(false)}
        open={calendlyModalOpen}
        rootElement={document.getElementById("root")}
      />
      <Modal
        show={props?.modalShow}
        onHide={props?.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="travel-team-modal"
      >
        <Modal.Header closeButton>
          <img
            src={require("../assets/images/modal/team6.png")}
            alt="TeamModalImg"
            className="d-block w-100 team-img"
            style={{
              width: "100%",
              height: 180,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={() => props.setModalHandle(false)}
            aria-label="Close"
            style={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              backgroundColor: "white",
              border: "none",
              borderRadius: "50%",
              width: "2rem",
              height: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body
          style={{
            height: "auto",
            padding: 0,
            paddingBottom: 16,
          }}
        >
          <Row
            style={{
              marginTop: "-75px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 150,
                height: 150,
                borderRadius: "50%",
                overflow: "hidden",
                padding: 0,
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={props?.profileImage}
                alt={props.cardTitleModal}
              />
            </div>
          </Row>
          <Row style={{ padding: 0 }}>
            <Col xs={5} className="pt-2" style={{ width: "90%" }}>
              <h2
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {props?.cardTitleModal}
              </h2>
            </Col>
          </Row>
          <Row style={{ padding: 0 }}>
            {props?.item.city && props?.item.state && (
              <Col xs={7} className="pt-4">
                <p
                  className="mb-0"
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#4B4E55",
                    letterSpacing: "0.045em",
                  }}
                >{`${props?.item?.city.toUpperCase() || ""}, ${
                  props?.item?.state
                }`}</p>
              </Col>
            )}
          </Row>
          <Row>
            {props?.item.email !== null && (
              <Col xs={5} className="pt-4">
                <h6 className="mb-0">E-mail:</h6>
              </Col>
            )}
            {props?.item.email !== null && (
              <Col xs={7} className="pt-4">
                <p className="mb-0">{props?.item.email}</p>
              </Col>
            )}
            {props?.item.phoneNumber !== null && (
              <Col xs={5} className="pt-4">
                <h6 className="mb-0">Phone number:</h6>
              </Col>
            )}
            {props?.item.phoneNumber !== null && (
              <Col xs={7} className="pt-4">
                <p className="mb-0">{props?.item.phoneNumber}</p>
              </Col>
            )}
            {props?.item?.website !== null && props?.item?.website !== null && (
              <Col xs={5} className="pt-4">
                <h6 className="mb-0">Website:</h6>
              </Col>
            )}
            {props?.item?.website !== null && props?.item?.website !== null && (
              <Col xs={5} className="pt-4">
                <p className="mb-0" style={{ color: "#F7941D" }}>
                  <a href={props?.item?.website} target="_blank">
                    Trainer Website
                  </a>
                </p>
              </Col>
            )}
            {props?.item.socialMediaHandles !== null && (
              <Col xs={5} className="pt-4">
                <h6 className="mb-0">Social Media:</h6>
              </Col>
            )}
            {props?.item.socialMediaHandles !== null && (
              <Col xs={7} className="pt-4">
                <p className="mb-0">{props?.item.socialMediaHandles}</p>
              </Col>
            )}
            {props?.item.company && (
              <Col xs={5} className="pt-4">
                <h6 className="mb-0">Company:</h6>
              </Col>
            )}
            {props?.item.company && (
              <Col xs={7} className="pt-4">
                <p className="mb-0">{props?.item.company}</p>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12} className="pt-4"></Col>
          </Row>
          <Row>
            <Col xs={12} className="pt-4">
              <div
                className="bg-dark"
                style={{
                  height: 160,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 10,
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: "27px",
                    textAlign: "center",
                    letterSpacing: "0.045em",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                    marginBottom: 10,
                  }}
                >
                  Would you like to speak to <br />
                  <span
                    style={{
                      color: "#F7941D",
                    }}
                  >
                    this trainer
                  </span>
                  ?
                </h1>
                <p className="mb-0">
                  <button
                    className="login-btn"
                    onClick={handleOpenCalendlyModal}
                    style={{
                      textDecoration: "none",
                      fontFamily: "Poppins",
                      background: "none",
                      fontWeight: 600,
                      fontSize: 13,
                      padding: 10,
                      width: 220,
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      textTransform: "uppercase",
                      letterSpacing: "0.045em",
                    }}
                  >
                    Speak to trainer
                  </button>
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
